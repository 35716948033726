import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionComparison.module.css';

import checkmarkDesktopImage from './images/checkmark-desktop.png';
import checkmarkMobileImage from './images/checkmark-mobile.png';
import clockDesktopImage from './images/clock-desktop.png';
import clockMobileImage from './images/clock-mobile.png';
import crossDesktopImage from './images/cross-desktop.png';
import crossMobileImage from './images/cross-mobile.png';
import leafDesktopImage from './images/leaf-desktop.png';
import leafMobileImage from './images/leaf-mobile.png';
import starDesktopImage from './images/star-desktop.png';
import starMobileImage from './images/star-mobile.png';

const SectionComparison = props => {
  const { rootClassName, className, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  const checkmarkImage = isMobileLayout ? checkmarkMobileImage : checkmarkDesktopImage;
  const clockImage = isMobileLayout ? clockMobileImage : clockDesktopImage;
  const crossImage = isMobileLayout ? crossMobileImage : crossDesktopImage;
  const leafImage = isMobileLayout ? leafMobileImage : leafDesktopImage;
  const starImage = isMobileLayout ? starMobileImage : starDesktopImage;

  return (
    <>
      <div className={classes}>
        <div className={css.title}>
          <FormattedMessage id="SectionComparison.title" />
        </div>
        <div className={css.mainBlock}>
          <div className={css.contentBlock}>
            <div className={css.gridOddRow}>
              <div className={css.rowTitle}>&nbsp;</div>
              <div className={css.rowCell}>Courses</div>
              <div className={css.rowCell}>Youtube</div>
              <div className={css.rowCell}>MentorME</div>
              <div className={css.rowCell}>Online Communities</div>
              <div className={css.rowCell}>Articles</div>
            </div>
            <div className={css.gridEvenRow}>
              <div className={css.rowTitle}>Expertise</div>
              <div className={css.rowCell}>
                <img src={starImage}/>
                <img src={starImage}/>
              </div>
              <div className={css.rowCell}><img src={starImage}/></div>
              <div className={css.rowCell}>
                <img src={starImage}/>
                <img src={starImage}/>
                <img src={starImage}/>
                <img src={starImage}/>
                <img src={starImage}/>
              </div>
              <div className={css.rowCell}>
                <img src={starImage}/>
                <img src={starImage}/>
              </div>
              <div className={css.rowCell}>
                <img src={starImage}/>
                <img src={starImage}/>
              </div>
            </div>
            <div className={css.gridOddRow}>
              <div className={css.rowTitle}>Price</div>
              <div className={css.rowCell}>£££</div>
              <div className={css.rowCell}><img src={clockImage}/></div>
              <div className={css.rowCell}>££</div>
              <div className={css.rowCell}>£</div>
              <div className={css.rowCell}><img src={clockImage}/></div>
            </div>
            <div className={css.gridEvenRow}>
              <div className={css.rowTitle}>Support</div>
              <div className={css.rowCell}><img src={crossImage}/></div>
              <div className={css.rowCell}><img src={crossImage}/></div>
              <div className={css.rowCell}><img src={checkmarkImage}/></div>
              <div className={css.rowCell}><img src={checkmarkImage}/></div>
              <div className={css.rowCell}><img src={crossImage}/></div>
            </div>
            <div className={css.gridOddRow}>
              <div className={css.rowTitle}>Transparency</div>
              <div className={css.rowCell}><img src={crossImage}/></div>
              <div className={css.rowCell}><img src={crossImage}/></div>
              <div className={css.rowCell}><img src={checkmarkImage}/></div>
              <div className={css.rowCell}><img src={crossImage}/></div>
              <div className={css.rowCell}><img src={crossImage}/></div>
            </div>
            <div className={css.gridEvenRow}>
              <div className={css.rowTitle}>Vetted</div>
              <div className={css.rowCell}><img src={crossImage}/></div>
              <div className={css.rowCell}><img src={crossImage}/></div>
              <div className={css.rowCell}><img src={checkmarkImage}/></div>
              <div className={css.rowCell}><img src={crossImage}/></div>
              <div className={css.rowCell}><img src={crossImage}/></div>
            </div>
            <div className={css.gridOddRow}>
              <div className={css.rowTitle}>&nbsp;</div>
              <div className={css.rowCell}>&nbsp;</div>
              <div className={css.rowCell}>&nbsp;</div>
              <div className={css.rowCell}>&nbsp;</div>
              <div className={css.rowCell}>&nbsp;</div>
              <div className={css.rowCell}>&nbsp;</div>
            </div>
            <div className={css.selectedColumn}>
              <div className={css.selectedTitle}>
                <img src={leafImage}/>
                <div>
                  MentorME
                </div>
              </div>
              <div className={css.selectedCell}>
                <img src={starImage}/>
                <img src={starImage}/>
                <img src={starImage}/>
                <img src={starImage}/>
                <img src={starImage}/>
              </div>
              <div className={css.selectedCell}>
                ££
              </div>
              <div className={css.selectedCell}>
                <img src={checkmarkImage}/>
              </div>
              <div className={css.selectedCell}>
                <img src={checkmarkImage}/>
              </div>
              <div className={css.selectedCell}>
                <img src={checkmarkImage}/>
              </div>
              <div className={css.selectedCell}>
                <NamedLink name="SignupPage" className={css.actionButton}>
                  <FormattedMessage id="SectionComparison.actionButton" />
                </NamedLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionComparison.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionComparison.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionComparison;
