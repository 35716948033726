import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InlineWidget } from "react-calendly";
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionSchedule.module.css';

import timeImage from './images/time.png';
import budgetImage from './images/budget.png';
import skillsImage from './images/skills.png';

const SectionSchedule = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={classes}>
        <div className={css.mainBlock}>
          <div className={css.contentBlock}>
            <div className={css.contentText}>
              <div className={css.title}>
                <FormattedMessage
                  id="SectionSchedule.title"
                  values={{
                    bookACall: (
                      <span className={css.titleHighlight }>
                        <FormattedMessage id="SectionSchedule.titleHighlight" />
                      </span>
                    )
                  }}
                />
              </div>
              <div className={css.traitLine}>
                <img src={timeImage}/>
                <span className={css.trailLabel}>
                  <FormattedMessage id="SectionSchedule.time" />
                </span>
              </div>
              <div className={css.traitLine}>
                <img src={budgetImage}/>
                <span className={css.trailLabel}>
                  <FormattedMessage id="SectionSchedule.budget" />
                </span>
              </div>
              <div className={css.traitLine}>
                <img src={skillsImage}/>
                <span className={css.trailLabel}>
                  <FormattedMessage id="SectionSchedule.skills" />
                </span>
              </div>
              <div className={css.getYouSetNote}>
                <FormattedMessage id="SectionSchedule.getYouSet" />
              </div>
            </div>
            <div className={css.contentCalendar}>
              <InlineWidget url="https://calendly.com/mentormeio" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionSchedule.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionSchedule.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSchedule;
