import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionStatistics.module.css';

const SectionStatistics = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.statCell}>
        <FormattedMessage id="SectionStatistics.statLabel1" values={{ br: <br/>}} />
        <div className={css.statFigure}>100+</div>
      </div>
      <div className={css.statDelimiter}>&nbsp;</div>
      <div className={css.statCell}>
        <FormattedMessage id="SectionStatistics.statLabel2" values={{ br: <br/>}} />
        <div className={css.statFigure}>1000+</div>
      </div>
      <div className={css.statDelimiter}>&nbsp;</div>
      <div className={css.statCell}>
        <FormattedMessage id="SectionStatistics.statLabel3" values={{ br: <br/>}} />
        <div className={css.statFigure}>1</div>
      </div>
      <div className={css.statCite}>
        <div className={css.citeText}>
          <FormattedMessage id="SectionStatistics.statCiteText"/>
        </div>
      </div>
    </div>
  );
};

SectionStatistics.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionStatistics.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionStatistics;
