import { storableError } from '../../util/errors';
import { adminFetchBusinessUsers, adminApproveUser } from '../../util/api';
import pick from 'lodash/pick';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/AdminManageUsersPage/SET_INITIAL_VALUES';

export const FETCH_USERS_REQUEST = 'app/AdminManageUsersPage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'app/AdminManageUsersPage/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'app/AdminManageUsersPage/FETCH_USERS_ERROR';

export const APPROVE_USER_REQUEST = 'app/AdminManageUsersPage/APPROVE_USER_REQUEST';
export const APPROVE_USER_SUCCESS = 'app/AdminManageUsersPage/APPROVE_USER_SUCCESS';
export const APPROVE_USER_ERROR = 'app/AdminManageUsersPage/APPROVE_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  fetchUsersInProgress: false,
  fetchUsersError: null,
  users: [],
  approvalUserId: null,
  approveUserInProgress: null,
  approveUserError: null,
};

const updateUserAttributes = ( users, user ) => {
  const updatedUsers = [];
  let userIndex = -1;

  users.forEach(( curUser, index ) => {
    if( user.id !== curUser.id ){
      updatedUsers.push( curUser );
    } else {
      userIndex = index;
    }
  });

  if( userIndex === -1 ) {
    updatedUsers.push(user);
  } else {
    updatedUsers.splice( userIndex, 0, user );
  }

  return updatedUsers;
};

const adminManageUsersPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_USERS_REQUEST:
      return {
        ...state,
        fetchUsersInProgress: true,
        fetchUsersError: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users,
        pagination: payload.meta,
        fetchUsersInProgress: false,
      };
    case FETCH_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchUsersInProgress: false, fetchUsersError: payload };

    case APPROVE_USER_REQUEST:
      return {
        ...state,
        approvalUserId: payload.userId,
        approveUserInProgress: true,
        approveUserError: null,
      };
    case APPROVE_USER_SUCCESS:
      return {
        users: updateUserAttributes( state.users, payload.user ),
        approvalUserId: null,
        approveUserInProgress: false,
      };
    case APPROVE_USER_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        approvalUserId: null,
        approveUserInProgress: false,
        approveUserError: {
          approvalUserId: state.approvalUserId,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default adminManageUsersPageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
  payload: {},
});

export const fetchUsersSuccess = response => ({
  type: FETCH_USERS_SUCCESS,
  payload: response,
});

export const fetchUsersError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

export const approveUserRequest = userId => ({
  type: APPROVE_USER_REQUEST,
  payload: { userId },
});

export const approveUserSuccess = response => ({
  type: APPROVE_USER_SUCCESS,
  payload: response,
});

export const approveUserError = e => ({
  type: APPROVE_USER_ERROR,
  error: true,
  payload: e,
});


export const fetchUsers = page => (dispatch, getState, sdk) => {
  dispatch( fetchUsersRequest());

  return adminFetchBusinessUsers( page )
    .then( response => {

      console.log('response');
      console.log( response );

      dispatch( fetchUsersSuccess( response ));

      return response;
    })
    .catch(e => {
      dispatch( fetchUsersError( storableError(e)));

      throw e;
    });
};

export const approveUser = userId => (dispatch, getState, sdk) => {
  dispatch( approveUserRequest({ userId }));

  return adminApproveUser( userId )
    .then(response => {
      dispatch( approveUserSuccess( response ));

      return response;
    })
    .catch(e => {
      dispatch(approveUserError( storableError(e)));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return dispatch( fetchUsers( 1 ));
};
