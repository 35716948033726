import delay from 'delay';
import axios from 'axios';
import qs from 'qs';
import { apiBaseUrl } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ReferralsPage/SET_INITIAL_STATE';

export const FETCH_PROFILE_LISTING_REQUEST = 'app/ReferralsPage/FETCH_PROFILE_LISTING_REQUEST';
export const FETCH_PROFILE_LISTING_SUCCESS = 'app/ReferralsPage/FETCH_PROFILE_LISTING_SUCCESS';
export const FETCH_PROFILE_LISTING_ERROR = 'app/ReferralsPage/FETCH_PROFILE_LISTING_ERROR';
export const FETCH_REFERRER_STATS_REQUEST = 'app/ReferralsPage/FETCH_REFERRER_STATS_REQUEST';
export const FETCH_REFERRER_STATS_SUCCESS = 'app/ReferralsPage/FETCH_REFERRER_STATS_SUCCESS';
export const FETCH_REFERRER_STATS_ERROR = 'app/ReferralsPage/FETCH_REFERRER_STATS_ERROR';

// ================ Reducer ================ //

const initialState = {
  profileListing: null,
  fetchProfileListingInProgress: false,
  fetchProfileListingError: null,
  isMentor: false,
  mentorRefereesCount: 0,
  menteeRefereesCount: 0,
  fetchReferrerStatsInProgress: false,
  fetchReferrerStatsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case FETCH_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        profileListing: null,
        fetchProfileListingInProgress: true,
        fetchProfileListingError: null,
      };
    case FETCH_PROFILE_LISTING_SUCCESS:
      return {
        ...state,
        profileListing: payload,
        fetchProfileListingInProgress: false,
      };
    case FETCH_PROFILE_LISTING_ERROR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: payload
      };

    case FETCH_REFERRER_STATS_REQUEST:
      return {
        ...state,
        isMentor: false,
        mentorRefereesCount: 0,
        menteeRefereesCount: 0,
        fetchReferrerStatsInProgress: true,
        fetchReferrerStatsError: null,
      };
    case FETCH_REFERRER_STATS_SUCCESS:
      return {
        ...state,
        isMentor: payload.isMentor,
        mentorRefereesCount: payload.mentorRefereesCount,
        menteeRefereesCount: payload.menteeRefereesCount,
        fetchProfileListingInProgress: false,
      };
    case FETCH_REFERRER_STATS_ERROR:
      return {
        ...state,
        fetchReferrerStatsInProgress: false,
        fetchReferrerStatsError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const fetchProfileListingRequest = () => ({ type: FETCH_PROFILE_LISTING_REQUEST });
export const fetchProfileListingSuccess = profileListing => ({
  type: FETCH_PROFILE_LISTING_SUCCESS,
  payload: profileListing,
});
export const fetchProfileListingError = error => ({
  type: FETCH_PROFILE_LISTING_ERROR,
  payload: error,
  error: true,
});

export const fetchReferrerStatsRequest = () => ({ type: FETCH_REFERRER_STATS_REQUEST });
export const fetchReferrerStatsSuccess = referrerStats => ({
  type: FETCH_REFERRER_STATS_SUCCESS,
  payload: referrerStats,
});
export const fetchReferrerStatsError = error => ({
  type: FETCH_REFERRER_STATS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const fetchProfileListing = () => (dispatch, getState, sdk) => {
  let currentUserId = null;
  let prospectReferralIds = [];

  for( let i = 0; i < 10; i++ ) {
    prospectReferralIds.push( Math.floor( Math.random() * 1000000000 ));
  }

  const prospectReferralQueries = prospectReferralIds.map( prospectReferralId =>
    sdk.listings.query({ pub_referralId: prospectReferralId })
  );

  dispatch(fetchProfileListingRequest());

  return sdk.currentUser.show()
    .then( result => {
      currentUserId = result.data.data.id;

      return sdk.listings.query({ authorId: currentUserId, pub_type: "internal_profile" });
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      if (entities.length === 1) {
        dispatch(fetchProfileListingSuccess(entities[0]));

        return Promise.resolve(null);
      }

      return Promise.all( prospectReferralQueries );
    })
    .then(response => {
      let referralId = null;

      if( Array.isArray( response )){
        response.forEach(( referralResponse, referralIndex ) => {
          if( !referralId && referralResponse.data.data.length === 0 ){
            referralId = prospectReferralIds[ referralIndex ];
          }
        });

        return sdk.ownListings.create({
          title: "Internal profile listing",
          description: "Listing which holds additional profile information like referral information",
          publicData: { referralId, type: "internal_profile" },
        });
      }

      return Promise.resolve(null);
    })
    .then(response => {
      if( response ){
        return delay(1000, { value: response });
      }

      return Promise.resolve(null);
    })
    .then(response => {
      if( response ){
        return sdk.listings.query({ authorId: currentUserId, pub_type: "internal_profile" });
      }

      return Promise.resolve(null);
    })
    .then(response => {
      dispatch(fetchReferrerStats());

      if( response ){
        const entities = denormalisedResponseEntities(response);
        if (entities.length === 1) {
          dispatch(fetchProfileListingSuccess(entities[0]));

          return Promise.resolve(response);
        }
      }

      return Promise.resolve(null);
    })
    .catch(e => {
      console.error(`fetchProfileListing failed with an error: ${e.message}`);
      dispatch(fetchProfileListingError(storableError(e)));
    });
};

export const fetchReferrerStats = () => (dispatch, getState, sdk) => {
  let currentUserId = null;
  let refereesList = null;
  let isMentor = false;
  let mentorRefereesCount = 0;
  let menteeRefereesCount = 0;

  dispatch(fetchReferrerStatsRequest());

  return sdk.currentUser.show()
    .then( result => {
      currentUserId = result.data.data.id;
      refereesList = result.data.data.attributes.profile.publicData.refereesList || [];

      return sdk.listings.query({ authorId: currentUserId, pub_type: "profile,course" });
    })
    // Find out if the current user has any published listings
    .then(response => {
      const services = response.data.data;

      if( Array.isArray(services) && services.length > 0 ) {
        services.forEach(service => {
          if (service.attributes.state === 'published') {
            isMentor = true;
          }
        });
      }

      const refereesListingQueries = refereesList.map( referreeId => {
        return sdk.listings.query({ authorId: new UUID(referreeId), pub_type: "profile,course" });
      });

      return Promise.all( refereesListingQueries );
    })
    .then(response => {
      if( Array.isArray(response) && response.length > 0 ){
        response.forEach( refereeListings => {
          const services = refereeListings.data.data;
          let isMentor = false;

          if( Array.isArray(services) && services.length > 0 ){
            services.forEach(service => {
              if (service.attributes.state === 'published') {
                isMentor = true;
              }
            })
          }

          if( isMentor ){
            mentorRefereesCount++;
          }
        });
      }

      const axiosData = { refereesList: JSON.stringify(refereesList)};

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(axiosData),
        url: `${apiBaseUrl()}/api/count-mentee-referees`,
      };

      return axios(options);
    })
    .then(response => {
      menteeRefereesCount = response.data.menteeRefereesCount;

      return dispatch(fetchReferrerStatsSuccess(
        { isMentor, mentorRefereesCount, menteeRefereesCount })
      );
    })
    .catch(e => {
      console.error(`fetchReferrerStats failed with an error: ${e.message}`);
      dispatch(fetchReferrerStatsError(storableError(e)));
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  dispatch(setInitialState());

  return dispatch( fetchProfileListing());
};
