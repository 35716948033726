/**
 * Export loadData calls from ducks modules of different containers
 */
import {
  loadData as AdminManageUsersPageLoader,
  setInitialValues as AdminManageUsersPageInitialValues,
} from './AdminManageUsersPage/AdminManageUsersPage.duck';
import {
  loadData as AdminManageReferralsPageLoader,
  setInitialValues as AdminManageReferralsPageInitialValues,
} from './AdminManageReferralsPage/AdminManageReferralsPage.duck';

import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as ConversationsPageLoader } from './ConversationsPage/ConversationsPage.duck';
import { loadData as ConversationThreadPageLoader } from './ConversationThreadPage/ConversationThreadPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EditProfileListingPageLoader } from './EditProfileListingPage/EditProfileListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfileListingPageLoader } from './ProfileListingPage/ProfileListingPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as ReferralQrCodePageLoader } from './ReferralQrCodePage/ReferralQrCodePage.duck';
import { loadData as ReferralsPageLoader } from './ReferralsPage/ReferralsPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    AdminManageUsersPage: {
      loadData: AdminManageUsersPageLoader,
      setInitialValues: AdminManageUsersPageInitialValues,
    },
    AdminManageReferralsPage: {
      loadData: AdminManageReferralsPageLoader,
      setInitialValues: AdminManageReferralsPageInitialValues,
    },

    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    ConversationsPage: {
      loadData: ConversationsPageLoader,
    },
    ConversationThreadPage: {
      loadData: ConversationThreadPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EditProfileListingPage: {
      loadData: EditProfileListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfileListingPage: {
      loadData: ProfileListingPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    ReferralQrCodePage: {
      loadData: ReferralQrCodePageLoader,
    },
    ReferralsPage: {
      loadData: ReferralsPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
  };
};

export default getPageDataLoadingAPI;
