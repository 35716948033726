import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';

import css from './EditListingTimelineForm.module.css';

const EditListingTimelineFormComponent = props => {
  const [numberOfLessons, setNumberOfLessons]
    = useState(props.numberOfLessons > 0 ? props.numberOfLessons : 1 );

  const copyValue = ([fieldName, newValue], state, { changeValue }) => {
    changeValue(state, fieldName, () => newValue)
  };

  const ffProps = {...props};
  ffProps.mutators = { copyValue };

  return (
    <FinalForm
      {...ffProps}
      render={formRenderProps => {
        const {
          className,
          form,
          values,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const removeLesson = lessonInd => {
          if( lessonInd < numberOfLessons - 1 ){
            for(let curInd = lessonInd; curInd < numberOfLessons - 1; curInd++){
              form.mutators.copyValue(
                `lessonDate${curInd + 1}`,
                values[`lessonDate${curInd + 2}`]
              );
              form.mutators.copyValue(
                `lessonTitle${curInd + 1}`,
                values[`lessonTitle${curInd + 2}`]
              );
              form.mutators.copyValue(
                `lessonStartTime${curInd + 1}`,
                values[`lessonStartTime${curInd + 2}`]
              );
              form.mutators.copyValue(
                `lessonEndTime${curInd + 1}`,
                values[`lessonEndTime${curInd + 2}`]
              );
            }
          }

          form.mutators.copyValue(
            `lessonDate${numberOfLessons}`, null
          );
          form.mutators.copyValue(
            `lessonTitle${numberOfLessons}`, null
          );
          form.mutators.copyValue(
            `lessonStartTime${numberOfLessons}`, null
          );
          form.mutators.copyValue(
            `lessonEndTime${numberOfLessons}`, null
          );

          setNumberOfLessons( numberOfLessons - 1);
        };

        const timelineIsInvalid = () => {
          let invalid = false;

          for( let lessonInd = 1; lessonInd <= numberOfLessons; lessonInd++ ){
            const startDateTime = new Date(
              values[`lessonDate${lessonInd}`] + ' ' + values[`lessonStartTime${lessonInd}`]
            );
            const endDateTime = new Date(
              values[`lessonDate${lessonInd}`] + ' ' + values[`lessonEndTime${lessonInd}`]
            );

            if( endDateTime.getTime() <= startDateTime.getTime()){
              invalid = true;
            }
          }

          return invalid;
        };

        const numberOfSeatsMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.numberOfSeatsLabel',
        });
        const numberOfSeatsPlaceholderMessage = intl.formatMessage(
          { id: 'EditListingTimelineForm.numberOfSeatsPlaceholder'},
        );
        const numberOfSeatsRequiredMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.numberOfSeatsRequired',
        });

        const lessonDateMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.lessonDateLabel',
        });
        const lessonDatePlaceholderMessage = intl.formatMessage(
          { id: 'EditListingTimelineForm.lessonDatePlaceholder'},
        );
        const lessonDateRequiredMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.lessonDateRequired',
        });
        const lessonTitleMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.lessonTitleLabel',
        });
        const lessonTitlePlaceholderMessage = intl.formatMessage(
          { id: 'EditListingTimelineForm.lessonTitlePlaceholder'},
        );
        const lessonTitleRequiredMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.lessonTitleRequired',
        });
        const lessonStartTimeMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.lessonStartTimeLabel',
        });
        const lessonStartTimePlaceholderMessage = intl.formatMessage(
          { id: 'EditListingTimelineForm.lessonStartTimePlaceholder'},
        );
        const lessonStartTimeRequiredMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.lessonStartTimeRequired',
        });
        const lessonEndTimeMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.lessonEndTimeLabel',
        });
        const lessonEndTimePlaceholderMessage = intl.formatMessage(
          { id: 'EditListingTimelineForm.lessonEndTimePlaceholder'},
        );
        const lessonEndTimeRequiredMessage = intl.formatMessage({
          id: 'EditListingTimelineForm.lessonEndTimeRequired',
        });

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingTimelineForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingTimelineForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingTimelineForm.showListingFailed" />
          </p>
        ) : null;

        const errorTimelineIsInvalid = timelineIsInvalid() ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingTimelineForm.timelineIsInvalid" />
          </p>
        ) : null;
        const lessons = (
          <>
            {Array.from( Array( numberOfLessons ).keys()).map(( val, index ) => (
              <div className={css.lessonBlock} key={`lessonBlock${index}`}>
                <div className={css.lessonHeaderLine}>
                  <div className={css.lessonHeaderNumber}>
                    <FormattedMessage
                      id="EditListingTimelineForm.lessonNumber"
                      values={{ lessonNumber: index + 1 }}
                    />
                  </div>
                  { numberOfLessons > 1 ? (
                    <div
                      className={css.lessonHeaderRemove}
                      onClick={() => removeLesson( index )}
                    >
                      <FormattedMessage id="EditListingTimelineForm.lessonRemove" />
                    </div>
                  ) : null }
                </div>
                <div className={css.lessonControlLine}>
                  <div className={css.lessonControlCell}>
                    <FieldTextInput
                      id={`lessonDate${ index + 1 }`}
                      name={`lessonDate${ index + 1 }`}
                      className={css.lessonControl}
                      rootClassName={css.lessonLeftControlRoot}
                      type="date"
                      label={lessonDateMessage}
                      placeholder={lessonDatePlaceholderMessage}
                      validate={composeValidators(required(lessonDateRequiredMessage))}
                    />
                  </div>
                  <div className={css.lessonControlCell}>
                    <FieldTextInput
                      id={`lessonTitle${ index + 1 }`}
                      name={`lessonTitle${ index + 1 }`}
                      className={css.lessonControl}
                      rootClassName={css.lessonRightControlRoot}
                      type="text"
                      label={lessonTitleMessage}
                      placeholder={lessonTitlePlaceholderMessage}
                      validate={composeValidators(required(lessonTitleRequiredMessage))}
                    />
                  </div>
                </div>
                <div className={css.lessonControlLine}>
                  <div className={css.lessonControlCell}>
                    <FieldTextInput
                      id={`lessonStartTime${ index + 1 }`}
                      name={`lessonStartTime${ index + 1 }`}
                      className={css.lessonControl}
                      rootClassName={css.lessonLeftControlRoot}
                      type="time"
                      label={lessonStartTimeMessage}
                      placeholder={lessonStartTimePlaceholderMessage}
                      validate={composeValidators(required(lessonStartTimeRequiredMessage))}
                    />
                  </div>
                  <div className={css.lessonControlCell}>
                    <FieldTextInput
                      id={`lessonEndTime${ index + 1 }`}
                      name={`lessonEndTime${ index + 1 }`}
                      className={css.lessonControl}
                      rootClassName={css.lessonRightControlRoot}
                      type="time"
                      label={lessonEndTimeMessage}
                      placeholder={lessonEndTimePlaceholderMessage}
                      validate={composeValidators(required(lessonEndTimeRequiredMessage))}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div
              className={css.lessonFooterLine}
              onClick={() => setNumberOfLessons( numberOfLessons + 1 )}
            >
              <FormattedMessage id="EditListingTimelineForm.lessonAdd" />
            </div>
          </>
        );

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || timelineIsInvalid() || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            {errorTimelineIsInvalid}
            <FieldTextInput
              id="numberOfSeats"
              name="numberOfSeats"
              className={css.numberOfSeats}
              type="number"
              min="1"
              label={numberOfSeatsMessage}
              placeholder={numberOfSeatsPlaceholderMessage}
              validate={composeValidators(required(numberOfSeatsRequiredMessage))}
            />



            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingTimelineFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingTimelineFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingTimelineFormComponent);
