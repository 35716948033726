import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';

import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink, NamedLink } from '../../components';

import css from './SearchResultsPanel.module.css';

import businessCoachingImage from './images/footer_business_coaching.png';
import eCommerceImage from './images/footer_e_commerce.png';
import propertyImage from './images/footer_property.png';
import tradingImage from './images/footer_trading.png';
import { findOptionsForSelectFilter } from '../../util/search';

const CATEGORY_IMAGES = {
  business_coaching: businessCoachingImage,
  e_commerce: eCommerceImage,
  property: propertyImage,
  trading: tradingImage,
};

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const CategoryBlock = categoryOption => {
  return(
    <NamedLink
      name="CategoryPage"
      params={{ category: categoryOption.key }}
      className={css.location}
    >
      <div className={css.footerImageWrapper}>
        <div className={css.footerAspectWrapper}>
          <LazyImage
            src={CATEGORY_IMAGES[ categoryOption.key ]}
            alt={categoryOption.label}
            className={css.locationImage}
          />
        </div>
      </div>
      <div className={css.linkText}>
        {categoryOption.label}
      </div>
    </NamedLink>
  );
};

const SectionCategoryExplainer = props => {
  const { rootClassName, className, categoryOption } = props;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const categoryOptionKeys = ['business_coaching', 'e_commerce', 'property', 'trading']
    .filter( categoryKey => categoryOption.key !== categoryKey );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.locations}>
        { categoryOptionKeys.map( categoryKey => CategoryBlock( categoryOptions.find( category => categoryKey === category.key )))}
      </div>
    </div>
  );
};

SectionCategoryExplainer.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCategoryExplainer.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategoryExplainer;
