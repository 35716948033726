import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import amazonImage from './images/category-fulfilment-by-amazon.jpg';
import ecommerceImage from './images/category-e-commerce.jpg';
import tradingImage from './images/category-trading.jpg';
import realEstateImage from './images/category-real-estate.jpg';
import businessCoachingImage from './images/category-business-coaching.jpg';
import comingSoonImage from './images/category-coming-soon.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, category) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="CategoryPage" params={{ category }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        { nameText }
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'E-Commerce',
          ecommerceImage,
          'e_commerce'
        )}
        {locationLink(
          'Trading & Investing',
          tradingImage,
          'trading'
        )}
      </div>
      <div className={css.locations}>
        {locationLink(
          'Property',
          realEstateImage,
          'property'
        )}
        {locationLink(
          'Business Coaching',
          businessCoachingImage,
          'business_coaching'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
