import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.module.css';

import bellIcon from './bell-icon.png';
import letterIcon from './letter-icon.png';
import ExternalLink from '../ExternalLink/ExternalLink';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    searchHidden,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    heroImageIndex = '',
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isAdmin = authenticatedOnClientSide && currentUser &&
    currentUser.attributes.profile.metadata.isAdmin;
  const isMainAdmin = isAdmin && currentUser?.id?.uuid === process.env.REACT_APP_MAIN_ADMIN_ID;
  const isMentor = currentUser && currentUser.attributes &&
    currentUser.attributes.profile && currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.role === 'mentor';

  const classes = classNames(rootClassName || css[`root`], className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      searchHidden={searchHidden}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const resourcesLink = (
    <NamedLink className={css.goToResourcesLink} name="AboutPage">
      <span className={css.resourcesLink}>
        <FormattedMessage id="TopbarDesktop.resourcesLink" />
      </span>
    </NamedLink>
  );

  const exploreLink = (
    <ExternalLink className={css.goToExploreLink} href="https://mentorme.io/blog/">
      <span className={css.exploreLink}>
        <FormattedMessage id="TopbarDesktop.exploreLink" />
      </span>
    </ExternalLink>
  );

  const applyToBeAMentorLink = !currentUser ? (
    <NamedLink className={css.applyToBeAMentorLink} name="SignupPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.applyToBeAMentor" />
        </span>
    </NamedLink>
  ) : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.orders" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const notificationsLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="ConversationsPage"
    >
      <span className={css.inbox}>
        <img src={bellIcon}/>
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const lettersLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="ConversationsPage"
    >
      <span className={css.inbox}>
        <img src={letterIcon}/>
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const adminManageUsersLink = isMainAdmin ? (
    <MenuItem key="AdminManageUsersPage">
      <NamedLink
        className={classNames(css.adminManageUsersLink, currentPageClass('AdminManageUsersPage'))}
        name="AdminManageUsersPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.adminManageUsersLink" />
      </NamedLink>
    </MenuItem>
  ) : null;

  const adminManageReferralsLink = isMainAdmin ? (
    <MenuItem key="AdminManageReferralsPage">
      <NamedLink
        className={classNames(css.adminManageUsersLink, currentPageClass('AdminManageReferralsPage'))}
        name="AdminManageReferralsPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.adminManageReferralsLink" />
      </NamedLink>
    </MenuItem>
  ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        { isMentor ? (
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
        ) : null }
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {adminManageUsersLink}
        {adminManageReferralsLink}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const createListingMenu = currentUser && isMentor ?
  (
    <Menu>
      <MenuLabel className={css.createListingLink} isOpenClassName={css.profileMenuIsOpen}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="NewProfileListingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('NewProfileListingPage'))}
            name="NewProfileListingPage"
          >
            <span>
              <FormattedMessage id="TopbarDesktop.createProfileListing" />
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="NewListingPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('NewListingPage'))}
            name="NewListingPage"
          >
            <FormattedMessage id="TopbarDesktop.createCourseListing" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {resourcesLink}
      {exploreLink}
      {applyToBeAMentorLink}
      {search}
      {createListingMenu}

      {lettersLink}
      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
