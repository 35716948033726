import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const UPDATE_USER_INFO_REQUEST = 'app/SignupExtraPage/UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'app/SignupExtraPage/UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_ERROR = 'app/SignupExtraPage/UPDATE_USER_INFO_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateInProgress: false,
  updateError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_USER_INFO_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateError: null,
      };
    case UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
      };
    case UPDATE_USER_INFO_ERROR:
      return {
        ...state,
        updateInProgress: false,
        updateProfileError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

// SDK method: sdk.currentUser.updateProfile
export const updateUserInfoRequest = params => ({
  type: UPDATE_USER_INFO_REQUEST,
  payload: { params },
});
export const updateUserInfoSuccess = result => ({
  type: UPDATE_USER_INFO_SUCCESS,
  payload: result.data,
});
export const updateUserInfoError = error => ({
  type: UPDATE_VETTING_INFO_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //

export const updateUserInfo = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateUserInfoRequest());

    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(
        {
          publicData: { ...actionPayload },
          privateData: { signupExtraSubmitted: true }
        },
        queryParams
      )
      .then(response => {
        dispatch(updateUserInfoSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateUserInfoError(storableError(e))));
  };
};
