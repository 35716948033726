import React, { useState, Component } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { InlineTextButton } from '../../components';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './SectionFaqContent.module.css';

import plusSignImage from './images/plus-sign.png';
import minusSignImage from './images/minus-sign.png';

class ExpandableText extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }

  render() {
    const { expand } = this.state;
    const { question, answer } = this.props;

    const handleShowAnswerClick = () => {
      this.setState({ expand: !this.state.expand });
    };

    return (
      <div className={css.stepItemRow}>
        <div className={css.stepItemTextRow}>
          <div className={css.stepItemActiveTitle}>
            {question}
          </div>
          { expand ? (
            <div className={css.stepItemActiveText}>
              {answer}
            </div>
          ) : null }
        </div>
        <div className={css.stepItemPipeRow}>
          <div className={css.stepItemActiveFigure} onClick={handleShowAnswerClick}>
            <img src={ expand ? minusSignImage : plusSignImage }/>
          </div>
        </div>
      </div>
    );
  }
}

const SectionFaqContent = props => {
  const [ selectedBlock, setSelectedBlock ] =
    useState( props.categoryOption ? props.categoryOption.key : null );
  const { rootClassName, className, categoryOption, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  const frequentlyAskedQuestions = config.custom.frequentlyAskedQuestions;
  const selectedBlockQuestions = frequentlyAskedQuestions[ selectedBlock ] ?
    frequentlyAskedQuestions[ selectedBlock ].questions : null;

  const answersBlock = selectedBlockQuestions ? (
    <div className={css.explanationStepBlocks}>
      <div className={css.explanationItemBlock}>
        {Object.keys( selectedBlockQuestions ).map( blockKey => (
          <ExpandableText
            key={`${selectedBlock}-${blockKey}`}
            question={selectedBlockQuestions[ blockKey ].question}
            answer={selectedBlockQuestions[ blockKey ].answer}
          />
        ))}
      </div>
    </div>
  ) : null;

  return answersBlock ? (
    <div className={classes}>
      <div className={css.faqTitle}>
        <FormattedMessage id={`SectionFaqContent.title_${categoryOption.key}`}/>
      </div>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          {answersBlock}
        </div>
      </div>
    </div>
  ) : null;
};

SectionFaqContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionFaqContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionFaqContent;
