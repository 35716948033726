import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_PROFILE_LISTING_REQUEST = 'app/ReferralQrCodePage/FETCH_PROFILE_LISTING_REQUEST';
export const FETCH_PROFILE_LISTING_SUCCESS = 'app/ReferralQrCodePage/FETCH_PROFILE_LISTING_SUCCESS';
export const FETCH_PROFILE_LISTING_ERROR = 'app/ReferralQrCodePage/FETCH_PROFILE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  profileListing: null,
  fetchProfileListingInProgress: false,
  fetchProfileListingError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        profileListing: null,
        fetchProfileListingInProgress: true,
        fetchProfileListingError: null,
      };
    case FETCH_PROFILE_LISTING_SUCCESS:
      return {
        ...state,
        profileListing: payload,
        fetchProfileListingInProgress: false,
      };
    case FETCH_PROFILE_LISTING_ERROR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchProfileListingRequest = () => ({ type: FETCH_PROFILE_LISTING_REQUEST });
export const fetchProfileListingSuccess = profileListing => ({
  type: FETCH_PROFILE_LISTING_SUCCESS,
  payload: profileListing,
});
export const fetchProfileListingError = error => ({
  type: FETCH_PROFILE_LISTING_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const fetchProfileListing = userId => (dispatch, getState, sdk) => {
  dispatch(fetchProfileListingRequest());

  return sdk.listings.query({ authorId: userId, pub_type: "internal_profile" })
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      if (entities.length !== 1) {
        throw new Error('User profile listing is not found');
      }

      return dispatch(fetchProfileListingSuccess(entities[0]));
    })
    .catch(e => {
      console.error(`fetchProfileListing failed with an error: ${e.message}`);
      dispatch(fetchProfileListingError(storableError(e)));
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  const id = new UUID(params.id);

  return dispatch( fetchProfileListing( id ));
};
