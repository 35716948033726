import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNavComponent = props => {
  const { className, rootClassName, selectedPageName, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  if( currentUser && currentUser.attributes && currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.role === 'mentor')
  {
    tabs.splice( 0, 0,
      {
        text: <FormattedMessage id="ManageListingsPage.yourListings" />,
        selected: selectedPageName === 'ManageListingsPage',
        linkProps: { name: 'ManageListingsPage'}
      }
    );
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return { currentUser };
};

const UserNav = compose(
  connect( mapStateToProps )
)( UserNavComponent);

export default UserNav;
