import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionPopularMentors.module.css';

import blankProfileImage from './images/blank_profile_image.png';
import activeDesktopArrowLeftImage from './images/active_desktop_arrow_left.png';
import activeDesktopArrowRightImage from './images/active_desktop_arrow_right.png';
import activeMobileArrowLeftImage from './images/active_mobile_arrow_left.png';
import activeMobileArrowRightImage from './images/active_mobile_arrow_right.png';

class MentorImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(MentorImage);

const locationLink = ( name, image, id ) => {
  const nameText = <span className={css.profileName}>{name}</span>;
  return (
    <NamedLink name="ProfilePage" params={{ id }} className={css.profile}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.profileImage} />
        </div>
      </div>
      <div className={css.linkText}>
        { nameText }
      </div>
    </NamedLink>
  );
};

const SectionPopularMentors = props => {
  const [ firstProfileInd, setFirstProfileInd ] = useState( 0 );

  const { rootClassName, className, isMobileLayout, profiles } = props;
  const classes = classNames(rootClassName || css.root, className);
  const numberOfVisibleProfiles = isMobileLayout ? 1 : 5;
  const visibleCoreProfiles = profiles.filter(( profile, index ) =>
    index >= firstProfileInd && index < firstProfileInd + numberOfVisibleProfiles
  );
  const visiblePrefixProfiles = firstProfileInd < 0 ?
    profiles.filter(( profile, index ) =>
      index >= profiles.length + firstProfileInd
    ) : [];
  const visibleSuffixProfiles = firstProfileInd > profiles.length - numberOfVisibleProfiles ?
    profiles.filter(( profile, index ) =>
      index < firstProfileInd + numberOfVisibleProfiles - profiles.length
    ) : [];
  const visibleProfiles = [
    ...visiblePrefixProfiles, ...visibleCoreProfiles, ...visibleSuffixProfiles
  ];

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage
          id="SectionPopularMentors.title"
          values={{
            mentors: (
              <span className={css.titleMentors} >
                <FormattedMessage id="SectionPopularMentors.titleMentors" />
              </span>
            )
          }}
        />
      </div>
      <div className={css.profiles}>
        <img
          src={activeDesktopArrowLeftImage}
          className={css.activeArrowDesktopLeftImage}
          onClick={() => setFirstProfileInd(
            firstProfileInd > -numberOfVisibleProfiles ?
              firstProfileInd - 1 :
              profiles.length - numberOfVisibleProfiles
          )}
        />
        { visibleProfiles.map( profile => (
          locationLink(
            profile.attributes.profile.displayName,
            profile.profileImage ?
              profile.profileImage.attributes.variants.default.url : blankProfileImage,
            profile.id.uuid
          )
        ))}
        <img
          src={activeDesktopArrowRightImage}
          className={css.activeArrowDesktopRightImage}
          onClick={
            () => setFirstProfileInd( firstProfileInd < profiles.length ? firstProfileInd + 1 : 0 )
          }
        />
      </div>
      <div className={css.arrowMobileRow}>
        <img
          src={activeMobileArrowLeftImage}
          className={css.activeArrowMobileLeftImage}
          onClick={() => setFirstProfileInd(
            firstProfileInd > -numberOfVisibleProfiles ?
              firstProfileInd - 1 :
              profiles.length - numberOfVisibleProfiles
          )}

        />
        <img
          src={activeMobileArrowRightImage}
          className={css.activeArrowMobileRightImage}
          onClick={
            () => setFirstProfileInd( firstProfileInd < profiles.length ? firstProfileInd + 1 : 0 )
          }
        />
      </div>
    </div>
  );
};

SectionPopularMentors.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionPopularMentors.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPopularMentors;
