import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import intlMessages from '../../translations/en.json';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const CLEAR_STATE = 'app/LandingPage/CLEAR_STATE';

export const FETCH_POPULAR_PROFILES_REQUEST = 'app/LandingPage/FETCH_POPULAR_PROFILES_REQUEST';
export const FETCH_POPULAR_PROFILES_SUCCESS = 'app/LandingPage/FETCH_POPULAR_PROFILES_SUCCESS';
export const FETCH_POPULAR_PROFILES_ERROR = 'app/LandingPage/FETCH_POPULAR_PROFILES_ERROR';

export const FETCH_BUSINESS_LISTINGS_REQUEST = 'app/LandingPage/FETCH_BUSINESS_LISTINGS_REQUEST';
export const FETCH_BUSINESS_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_BUSINESS_LISTINGS_SUCCESS';
export const FETCH_BUSINESS_LISTINGS_ERROR = 'app/LandingPage/FETCH_BUSINESS_LISTINGS_ERROR';

export const FETCH_SIDE_HUSTLE_LISTINGS_REQUEST = 'app/LandingPage/FETCH_SIDE_HUSTLE_LISTINGS_REQUEST';
export const FETCH_SIDE_HUSTLE_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_SIDE_HUSTLE_LISTINGS_SUCCESS';
export const FETCH_SIDE_HUSTLE_LISTINGS_ERROR = 'app/LandingPage/FETCH_SIDE_HUSTLE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchProfilesInProgress: false,
  fetchProfilesError: null,
  popularProfileResultIds: [],
  fetchBusinessListingsInProgress: false,
  fetchBusinessListingsError: null,
  businessListingResultIds: [],
  fetchSideHustleListingsInProgress: false,
  fetchSideHustleListingsError: null,
  sideHustleListingResultIds: [],
};

const resultIds = payload => payload.map(r => r?.data?.data?.id);

const assemblePopularProfileIds = intlMessages => {
  const profileIds = [];

  console.log("LandingPage duck assemblePopularProfileIds ");

  for( let profileInd = 1; profileInd <= 10; profileInd++ ) {
    const profileId = intlMessages[`LandingPage.popularProfileId${profileInd}`];

    if( profileId )
      profileIds.push( profileId );
  }

  return profileIds;
};

const assembleSideHustleListingIds = intlMessages => {
  const listingIds = [];

  for( let listingInd = 1; listingInd <= 10; listingInd++ ) {
    const listingId = intlMessages[`LandingPage.sideHustleListingId${listingInd}`];

    if( listingId )
      listingIds.push( listingId );
  }

  return listingIds;
};

const assembleBusinessListingIds = intlMessages => {
  const listingIds = [];

  for( let listingInd = 1; listingInd <= 10; listingInd++ ) {
    const listingId = intlMessages[`LandingPage.businessListingId${listingInd}`];

    if( listingId )
      listingIds.push( listingId );
  }

  return listingIds;
};

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_STATE:
      return { ...initialState };

    case FETCH_POPULAR_PROFILES_REQUEST:
      return {
        ...state,
        fetchProfilesInProgress: true,
        fetchProfilesError: null,
      };
    case FETCH_POPULAR_PROFILES_SUCCESS:
      return {
        ...state,
        popularProfileResultIds: resultIds(payload),
        fetchProfilesInProgress: false,
      };
    case FETCH_POPULAR_PROFILES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchProfilesInProgress: false,
        fetchProfilesError: payload
      };

    case FETCH_BUSINESS_LISTINGS_REQUEST:
      return {
        ...state,
        fetchBusinessListingsInProgress: true,
        fetchBusinessListingsError: null,
      };
    case FETCH_BUSINESS_LISTINGS_SUCCESS:
      return {
        ...state,
        businessListingResultIds: resultIds(payload),
        fetchBusinessListingsInProgress: false,
      };
    case FETCH_BUSINESS_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchBusinessListingsInProgress: false,
        fetchBusinessListingsError: payload
      };

    case FETCH_SIDE_HUSTLE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchSideHustleListingsInProgress: true,
        fetchSideHustleListingsError: null,
      };
    case FETCH_SIDE_HUSTLE_LISTINGS_SUCCESS:
      return {
        ...state,
        sideHustleListingResultIds: resultIds(payload),
        fetchSideHustleListingsInProgress: false,
      };
    case FETCH_SIDE_HUSTLE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchSideHustleListingsInProgress: false,
        fetchSideHustleListingsError: payload
      };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const clearState = () => ({
  type: CLEAR_STATE,
  payload: {},
});

export const fetchPopularProfilesRequest = () => ({
  type: FETCH_POPULAR_PROFILES_REQUEST,
  payload: {},
});

export const fetchPopularProfilesSuccess = response => ({
  type: FETCH_POPULAR_PROFILES_SUCCESS,
  payload: response,
});

export const fetchPopularProfilesError = e => ({
  type: FETCH_POPULAR_PROFILES_ERROR,
  error: true,
  payload: e,
});

export const fetchBusinessListingsRequest = () => ({
  type: FETCH_BUSINESS_LISTINGS_REQUEST,
  payload: {},
});

export const fetchBusinessListingsSuccess = response => ({
  type: FETCH_BUSINESS_LISTINGS_SUCCESS,
  payload: response,
});

export const fetchBusinessListingsError = e => ({
  type: FETCH_BUSINESS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchSideHustleListingsRequest = () => ({
  type: FETCH_SIDE_HUSTLE_LISTINGS_REQUEST,
  payload: {},
});

export const fetchSideHustleListingsSuccess = response => ({
  type: FETCH_SIDE_HUSTLE_LISTINGS_SUCCESS,
  payload: response,
});

export const fetchSideHustleListingsError = e => ({
  type: FETCH_SIDE_HUSTLE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchPopularProfiles = profileIds => (dispatch, getState, sdk) => {
  dispatch( fetchPopularProfilesRequest( profileIds ));

  const profilePromises = [];

  if (Array.isArray(profileIds) && profileIds.length > 0) {
    profileIds.forEach(profileId =>
      profilePromises.push(
        sdk.users.show({
          id: new UUID(profileId),
          include: ['profileImage']
        }).catch(e => console.error(e))
      )
    );
  } else {
    return Promise.resolve([]);
  }

  return Promise.all( profilePromises )
    .then(response => {
      response.forEach(record => !!record && dispatch(addMarketplaceEntities(record)));
      dispatch(fetchPopularProfilesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch( fetchPopularProfilesError( storableError( e )));
      console.error( e );
    });
};

export const fetchBusinessListings = listingIds => (dispatch, getState, sdk) => {
  dispatch( fetchBusinessListingsRequest( listingIds ));

  const listingPromises = [];

  if (Array.isArray(listingIds) && listingIds.length > 0) {
    listingIds.forEach(listingId =>
      listingPromises.push(
        sdk.listings.show({
          id: new UUID( listingId ),
          include: ['images', 'author']
        }).catch(e => console.error(e))
      )
    );
  } else {
    return Promise.resolve([]);
  }

  return Promise.all( listingPromises )
    .then(response => {
      response.forEach(record => !!record && dispatch(addMarketplaceEntities(record)));
      dispatch( fetchBusinessListingsSuccess( response ));
      return response;
    })
    .catch(e => {
      dispatch( fetchBusinessListingsError( storableError( e )));
      console.error( e );
    });
};

export const fetchSideHustleListings = listingIds => (dispatch, getState, sdk) => {
  dispatch( fetchSideHustleListingsRequest( listingIds ));

  const listingPromises = [];

  if ( Array.isArray( listingIds ) && listingIds.length > 0 ) {
    listingIds.forEach(listingId =>
      listingPromises.push(
        sdk.listings.show({
          id: new UUID( listingId ),
          include: ['images', 'author']
        }).catch(e => console.error(e))
      )
    );
  } else {
    return Promise.resolve([]);
  }

  return Promise.all( listingPromises )
    .then(response => {
      response.forEach(record => !!record && dispatch(addMarketplaceEntities(record)));
      dispatch( fetchSideHustleListingsSuccess( response ));
      return response;
    })
    .catch(e => {
      dispatch( fetchSideHustleListingsError( storableError( e )));
      console.error( e );
    });
};

export const fetchAll = ( profileIds, businessIds, hustleIds ) =>  (dispatch, getState, sdk) => {

  dispatch( clearState());

  return dispatch( fetchPopularProfiles( profileIds ))
    .then(() => {
      return dispatch( fetchBusinessListings( businessIds ));
    })
    .then(() => {
      return dispatch( fetchSideHustleListings( hustleIds ));
    });
};

export const loadData = () => {
  const profileIds = assemblePopularProfileIds( intlMessages );
  const businessListingIds = assembleBusinessListingIds( intlMessages );
  const sideHustleListingIds = assembleSideHustleListingIds( intlMessages );

  return fetchAll( profileIds, businessListingIds, sideHustleListingIds );
};
