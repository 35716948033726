import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './BookVettingForm.module.css';

const BookVettingFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
      } = fieldRenderProps;

      const nameLabel = intl.formatMessage({
        id: 'BookVettingForm.nameLabel'
      });
      const namePlaceholder = intl.formatMessage({
        id: 'BookVettingForm.namePlaceholder',
      });
      const nameRequiredMessage = intl.formatMessage({
        id: 'BookVettingForm.nameRequired',
      });
      const nameRequired = validators.requiredAndNonEmptyString(nameRequiredMessage);

      const emailLabel = intl.formatMessage({
        id: 'BookVettingForm.emailLabel'
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'BookVettingForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'BookVettingForm.emailRequired',
      });
      const emailRequired = validators.requiredAndNonEmptyString(emailRequiredMessage);

      const budgetLabel = intl.formatMessage({
        id: 'BookVettingForm.budgetLabel'
      });
      const budgetPlaceholder = intl.formatMessage({
        id: 'BookVettingForm.budgetPlaceholder',
      });
      const budgetRequiredMessage = intl.formatMessage({
        id: 'BookVettingForm.budgetRequired',
      });
      const budgetRequired = validators.requiredAndNonEmptyString(budgetRequiredMessage);

      const achieventsLabel = intl.formatMessage({
        id: 'BookVettingForm.achievementsLabel'
      });
      const achieventsPlaceholder = intl.formatMessage({
        id: 'BookVettingForm.achievementsPlaceholder',
      });
      const achieventsRequiredMessage = intl.formatMessage({
        id: 'BookVettingForm.achievementsRequired',
      });
      const achieventsRequired = validators.requiredAndNonEmptyString(achieventsRequiredMessage);

      const actionsLabel = intl.formatMessage({
        id: 'BookVettingForm.actionsLabel'
      });
      const actionsPlaceholder = intl.formatMessage({
        id: 'BookVettingForm.actionsPlaceholder',
      });
      const actionsRequiredMessage = intl.formatMessage({
        id: 'BookVettingForm.actionsRequired',
      });
      const actionsRequired = validators.requiredAndNonEmptyString(actionsRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <IconEnquiry className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="BookVettingForm.heading" values={{ listingTitle }} />
          </h2>
          <FieldTextInput
            className={css.field}
            type="text"
            name="name"
            id={formId ? `${formId}.name` : 'name'}
            label={nameLabel}
            placeholder={namePlaceholder}
            validate={nameRequired}
          />
          <FieldTextInput
            className={css.field}
            type="text"
            name="email"
            id={formId ? `${formId}.email` : 'email'}
            label={emailLabel}
            placeholder={emailPlaceholder}
            validate={emailRequired}
          />
          <FieldTextInput
            className={css.field}
            type="text"
            name="budget"
            id={formId ? `${formId}.budget` : 'budget'}
            label={budgetLabel}
            placeholder={budgetPlaceholder}
            validate={budgetRequired}
          />
          <FieldTextInput
            className={css.field}
            type="text"
            name="achievents"
            id={formId ? `${formId}.achievents` : 'achievents'}
            label={achieventsLabel}
            placeholder={achieventsPlaceholder}
            validate={achieventsRequired}
          />
          <FieldTextInput
            className={css.field}
            type="text"
            name="actions"
            id={formId ? `${formId}.actions` : 'actions'}
            label={actionsLabel}
            placeholder={actionsPlaceholder}
            validate={actionsRequired}
          />

          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="BookVettingForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="BookVettingForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

BookVettingFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

BookVettingFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookVettingForm = compose(injectIntl)(BookVettingFormComponent);

BookVettingForm.displayName = 'BookVettingForm';

export default BookVettingForm;
