import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';

import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink, NamedLink } from '../../components';

import css from './SearchResultsPanel.module.css';

import businessCoachingImage from './images/business_coaching.png';
import eCommerceImage from './images/e_commerce.png';
import propertyImage from './images/property.png';
import tradingImage from './images/trading.png';
import { findOptionsForSelectFilter } from '../../util/search';

const CATEGORY_IMAGES = {
  business_coaching: businessCoachingImage,
  e_commerce: eCommerceImage,
  property: propertyImage,
  trading: tradingImage,
};

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const categoryBlock = categoryOption => {
  return(
    <>
      <div className={css.location}>
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <LazyImage
              src={CATEGORY_IMAGES[ categoryOption.key ]}
              alt={categoryOption.label}
              className={css.locationImage}
            />
          </div>
        </div>
      </div>
      <div className={css.locationDescription}>
        <h2 className={css.categorySubTitle}>
          <FormattedMessage id={`SearchResultsPanel.pageSubheaderTitle2_${categoryOption.key}`} />
        </h2>
        <div className={css.categorySubDescription}>
          <FormattedMessage id={`SearchResultsPanel.pageSubheaderText2_${categoryOption.key}`} />
        </div>
        <NamedLink name="SignupPage" className={css.actionButton1}>
          <FormattedMessage id="SearchResultsPanel.actionButton1" />
        </NamedLink>
      </div>
    </>
  );
};

const SectionCategoryExplainer = props => {
  const { rootClassName, className, categoryOption } = props;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const categoryOptionKeys = ['business_coaching', 'e_commerce', 'property', 'trading']
    .filter( categoryKey => categoryOption.key !== categoryKey );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.locations}>
        {categoryBlock( categoryOption )}
      </div>
    </div>
  );
};

SectionCategoryExplainer.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCategoryExplainer.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategoryExplainer;
