import React from 'react';
import { FieldSelect } from '../../components';

import css from './EditProfileListingDescriptionForm.module.css';

const CustomCertificateSelectFieldMaybe = props => {
  const { name, id, categoryOptions, intl } = props;
  const categoryLabel = intl.formatMessage({
    id: 'EditProfileListingDescriptionForm.certificateLabel',
  });

  return categoryOptions ? (
    <FieldSelect className={css.certificate} name={name} id={id} label={categoryLabel}>
      {categoryOptions.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomCertificateSelectFieldMaybe;
