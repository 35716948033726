import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { findOptionsForSelectFilter } from '../../util/search';
import * as validators from '../../util/validators';
import {
  Form, PrimaryButton, FieldTextInput, FieldSelect, FieldCurrencyInput
} from '../../components';

import css from './VettingPart2Form.module.css';
import config from '../../config';

const VettingPart2FormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
      } = formRenderProps;

      // Course delivery
      const courseDeliveryLabel = intl.formatMessage({
        id: 'VettingPart2Form.courseDeliveryLabel',
      });
      const courseDeliveryPlaceholder = intl.formatMessage({
        id: 'VettingPart2Form.courseDeliveryPlaceholder',
      });
      const courseDeliveryOptionVideo = intl.formatMessage({
        id: 'VettingPart2Form.courseDeliveryOptionVideo',
      });
      const courseDeliveryOptionInPerson = intl.formatMessage({
        id: 'VettingPart2Form.courseDeliveryOptionInPerson',
      });
      const courseDeliveryOptionOther = intl.formatMessage({
        id: 'VettingPart2Form.courseDeliveryOptionOther',
      });
      const courseDeliveryRequiredMessage = intl.formatMessage({
        id: 'VettingPart2Form.courseDeliveryRequired',
      });
      const courseDeliveryRequired = validators.required(courseDeliveryRequiredMessage);

      // Mentees number
      const menteesNumberLabel = intl.formatMessage({
        id: 'VettingPart2Form.menteesNumberLabel',
      });
      const menteesNumberPlaceholder = intl.formatMessage({
        id: 'VettingPart2Form.menteesNumberPlaceholder',
      });
      const menteesNumberOption1To10 = intl.formatMessage({
        id: 'VettingPart2Form.menteesNumberOption1To10',
      });
      const menteesNumberOption11To50 = intl.formatMessage({
        id: 'VettingPart2Form.menteesNumberOption11To50',
      });
      const menteesNumberOption51To100 = intl.formatMessage({
        id: 'VettingPart2Form.menteesNumberOption51To100',
      });
      const menteesNumberOptionMoreThan100 = intl.formatMessage({
        id: 'VettingPart2Form.menteesNumberOptionMoreThan100',
      });
      const menteesNumberRequiredMessage = intl.formatMessage({
        id: 'VettingPart2Form.menteesNumberRequired',
      });
      const menteesNumberRequired = validators.required(menteesNumberRequiredMessage);

      // Reviews presence
      const reviewsPresenceLabel = intl.formatMessage({
        id: 'VettingPart2Form.reviewsPresenceLabel',
      });
      const reviewsPresencePlaceholder = intl.formatMessage({
        id: 'VettingPart2Form.reviewsPresencePlaceholder',
      });
      const reviewsPresenceOptionYes = intl.formatMessage({
        id: 'VettingPart2Form.reviewsPresenceOptionYes',
      });
      const reviewsPresenceOptionNo = intl.formatMessage({
        id: 'VettingPart2Form.reviewsPresenceOptionNo',
      });
      const reviewsPresenceRequiredMessage = intl.formatMessage({
        id: 'VettingPart2Form.reviewsPresenceRequired',
      });
      const reviewsPresenceRequired = validators.required(reviewsPresenceRequiredMessage);

      // Social media links
      const socialMediaLinksLabel = intl.formatMessage({
        id: 'VettingPart2Form.socialMediaLinksLabel',
      });
      const socialMediaLinksPlaceholder = intl.formatMessage({
        id: 'VettingPart2Form.socialMediaLinksPlaceholder',
      });
      const socialMediaLinksRequiredMessage = intl.formatMessage({
        id: 'VettingPart2Form.socialMediaLinksRequired',
      });
      const socialMediaLinksRequired = validators.required(socialMediaLinksRequiredMessage);

      // Experience
      const experienceLabel = intl.formatMessage({
        id: 'VettingPart2Form.experienceLabel',
      });
      const experiencePlaceholder = intl.formatMessage({
        id: 'VettingPart2Form.experiencePlaceholder',
      });
      const experienceRequiredMessage = intl.formatMessage({
        id: 'VettingPart2Form.experienceRequired',
      });
      const experienceRequired = validators.required(experienceRequiredMessage);

      // Pricing
      const pricingLabel = intl.formatMessage({
        id: 'VettingPart2Form.pricingLabel',
      });
      const pricingPlaceholder = intl.formatMessage({
        id: 'VettingPart2Form.pricingPlaceholder',
      });
      const pricingRequiredMessage = intl.formatMessage({
        id: 'VettingPart2Form.pricingRequired',
      });
      const pricingRequired = validators.required(pricingRequiredMessage);

      // Turnover
      const turnoverLabel = intl.formatMessage({
        id: 'VettingPart2Form.turnoverLabel',
      });
      const turnoverPlaceholder = intl.formatMessage({
        id: 'VettingPart2Form.turnoverPlaceholder',
      });
      const turnoverRequiredMessage = intl.formatMessage({
        id: 'VettingPart2Form.turnoverRequired',
      });
      const turnoverRequired = validators.required(turnoverRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldSelect
              id="courseDelivery"
              name="courseDelivery"
              className={css.courseDeliveryInput}
              label={courseDeliveryLabel}
              validate={courseDeliveryRequired}
            >
              <option disabled value="">
                {courseDeliveryPlaceholder}
              </option>
              <option value="video">
                {courseDeliveryOptionVideo}
              </option>
              <option value="in-person">
                {courseDeliveryOptionInPerson}
              </option>
              <option value="other">
                {courseDeliveryOptionOther}
              </option>
            </FieldSelect>
            <FieldSelect
              id="menteesNumber"
              name="menteesNumber"
              className={css.menteesNumberInput}
              label={menteesNumberLabel}
              validate={menteesNumberRequired}
            >
              <option disabled value="">
                {menteesNumberPlaceholder}
              </option>
              <option value="1-10">
                {menteesNumberOption1To10}
              </option>
              <option value="11-50">
                {menteesNumberOption11To50}
              </option>
              <option value="51-100">
                {menteesNumberOption51To100}
              </option>
              <option value="more-than-100">
                {menteesNumberOptionMoreThan100}
              </option>
            </FieldSelect>
            <FieldSelect
              id="reviewsPresence"
              name="reviewsPresence"
              className={css.reviewsPresenceInput}
              label={reviewsPresenceLabel}
              validate={reviewsPresenceRequired}
            >
              <option disabled value="">
                {reviewsPresencePlaceholder}
              </option>
              <option value="yes">
                {reviewsPresenceOptionYes}
              </option>
              <option value="no">
                {reviewsPresenceOptionNo}
              </option>
            </FieldSelect>
            <FieldTextInput
              type="textarea"
              id={formId ? `${formId}.socialMediaLinks` : 'socialMediaLinks'}
              name="socialMediaLinks"
              className={css.socialMediaLinksInput}
              label={socialMediaLinksLabel}
              placeholder={socialMediaLinksPlaceholder}
              validate={socialMediaLinksRequired}
            />
            <FieldTextInput
              type="textarea"
              id={formId ? `${formId}.experience` : 'experience'}
              name="experience"
              className={css.experienceInput}
              label={experienceLabel}
              placeholder={experiencePlaceholder}
              validate={experienceRequired}
            />
            <FieldCurrencyInput
              id="pricing"
              name="pricing"
              className={css.pricingInput}
              label={pricingLabel}
              placeholder={pricingPlaceholder}
              currencyConfig={config.currencyConfig}
              validate={pricingRequired}
            />
            <FieldCurrencyInput
              id="turnover"
              name="turnover"
              className={css.turnoverInput}
              label={turnoverLabel}
              placeholder={turnoverPlaceholder}
              currencyConfig={config.currencyConfig}
              validate={turnoverRequired}
            />
          </div>

          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="VettingPart2Form.finish" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

VettingPart2FormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

VettingPart2FormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const VettingPart2Form = compose(injectIntl)(VettingPart2FormComponent);
VettingPart2Form.displayName = 'VettingPart2Form';

export default VettingPart2Form;
