import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { getNameList, getCodeList } from 'country-list';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckboxGroup,
  FieldSelect
} from '../../components';

import css from './SignupExtraForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupExtraFormComponent = props => {
  const setValue = ([fieldName, newValue], state, { changeValue }) => {
    changeValue(state, fieldName, () => newValue)
  };

  return (
    <FinalForm
      {...props}
      mutators={{ setValue, ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          form,
          values,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
        } = fieldRenderProps;

        // dateOfBirth
        const dateOfBirthLabel = intl.formatMessage({
          id: 'SignupExtraForm.dateOfBirthLabel',
        });
        const dateOfBirthPlaceholder = intl.formatMessage({
          id: 'SignupExtraForm.dateOfBirthPlaceholder',
        });
        const dateOfBirthRequiredMessage = intl.formatMessage({
          id: 'SignupExtraForm.dateOfBirthRequired',
        });
        const dateOfBirthRequired = validators.required(dateOfBirthRequiredMessage);

        // Gender
        const genderOptions = [
          { key: 'male', label: intl.formatMessage({ id: 'SignupExtraForm.genderMaleOption' }) },
          { key: 'female', label: intl.formatMessage({ id: 'SignupExtraForm.genderFemaleOption' }) }
        ];

        const genderLabel = intl.formatMessage({
          id: 'SignupExtraForm.genderLabel',
        });
        const genderRequiredMessage = intl.formatMessage({
          id: 'SignupExtraForm.genderRequired',
        });
        const genderRequired = validators.required(genderRequiredMessage);

        const countriesList = getCodeList();
        const countryNames = {};
        Object.keys( countriesList ).forEach( countryCode => {
          countryNames[ countriesList[ countryCode ]] = countryCode;
        });

        // country
        const countryLabel = intl.formatMessage({
          id: 'SignupExtraForm.countryLabel',
        });
        const countryPlaceholder = intl.formatMessage({
          id: 'SignupExtraForm.countryPlaceholder',
        });
        const countryRequiredMessage = intl.formatMessage({
          id: 'SignupExtraForm.countryRequired',
        });
        const countryRequired = validators.required(countryRequiredMessage);

        // role
        const roleLabel = intl.formatMessage({
          id: 'SignupExtraForm.roleLabel',
        });
        const rolePlaceholder = intl.formatMessage({
          id: 'SignupExtraForm.rolePlaceholder',
        });
        const roleRequiredMessage = intl.formatMessage({
          id: 'SignupExtraForm.roleRequired',
        });
        const roleOptionMentor = intl.formatMessage({
          id: 'SignupExtraForm.roleOptionMentor',
        });
        const roleOptionMentee = intl.formatMessage({
          id: 'SignupExtraForm.roleOptionMentee',
        });

        const roleRequired = validators.required(roleRequiredMessage);

        // lead source
        const leadSourceLabel = intl.formatMessage({
          id: 'SignupExtraForm.leadSourceLabel',
        });
        const leadSourcePlaceholder = intl.formatMessage({
          id: 'SignupExtraForm.leadSourcePlaceholder',
        });
        const leadSourceRequiredMessage = intl.formatMessage({
          id: 'SignupExtraForm.leadSourceRequired',
        });
        const leadSourceOptionAdvertisement = intl.formatMessage({
          id: 'SignupExtraForm.leadSourceOptionAdvertisement',
        });
        const leadSourceOptionTheInternet = intl.formatMessage({
          id: 'SignupExtraForm.leadSourceOptionTheInternet',
        });
        const leadSourceOptionWordOfMouth = intl.formatMessage({
          id: 'SignupExtraForm.leadSourceOptionWordOfMouth',
        });
        const leadSourceOptionEvents = intl.formatMessage({
          id: 'SignupExtraForm.leadSourceOptionEvents',
        });
        const leadSourceOptionOther = intl.formatMessage({
          id: 'SignupExtraForm.leadSourceOptionOther',
        });

        const leadSourceRequired = validators.required(leadSourceRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                className={css.birthday}
                type="date"
                id={formId ? `${formId}.birthday` : 'birthday'}
                name="birthday"
                autoComplete="date"
                label={dateOfBirthLabel}
                placeholder={dateOfBirthPlaceholder}
                validate={dateOfBirthRequired}
              />
              <div className={css.genderCountry}>
                <FieldCheckboxGroup
                  className={css.genderRoot}
                  id={formId ? `${formId}.gender` : 'gender'}
                  name="gender"
                  label={genderLabel}
                  twoColumns={false}
                  options={genderOptions}
                  validate={genderRequired}
                />
                <OnChange name="gender">
                  {(value, previous) => {
                    const selectedGenders = value || [];
                    const previousGenders = previous || [];

                    if( selectedGenders.length === 0 ){
                      form.mutators.setValue('gender', previousGenders );
                    } else if( selectedGenders.length === 2 ){
                      const newSelected =
                        selectedGenders.filter( gender => !previousGenders.includes( gender ));

                      form.mutators.setValue('gender', newSelected );
                    }
                  }}
                </OnChange>
                <FieldSelect
                  id="country"
                  name="country"
                  className={css.countryRoot}
                  autoComplete="country"
                  label={countryLabel}
                  validate={countryRequired}
                >
                  <option disabled value="">
                    {countryPlaceholder}
                  </option>
                  {Object.keys( countryNames ).sort().map( cname => (
                    <option key={ countryNames[ cname ]} value={ countryNames[ cname ]}>
                      { cname }
                    </option>
                  ))}
                </FieldSelect>
              </div>
              <FieldSelect
                id="role"
                name="role"
                className={css.role}
                label={roleLabel}
                validate={roleRequired}
              >
                <option disabled value="">
                  {rolePlaceholder}
                </option>
                <option key='mentor' value='mentor'>
                  {roleOptionMentor}
                </option>
                <option key='mentee' value='mentee'>
                  {roleOptionMentee}
                </option>
              </FieldSelect>
              <FieldSelect
                id="leadSource"
                name="leadSource"
                className={css.leadSource}
                label={leadSourceLabel}
                validate={leadSourceRequired}
              >
                <option disabled value="">
                  {leadSourcePlaceholder}
                </option>
                <option key='advertisement' value='advertisement'>
                  {leadSourceOptionAdvertisement}
                </option>
                <option key='internet' value='internet'>
                  {leadSourceOptionTheInternet}
                </option>
                <option key='word-of-mouth' value='word-of-mouth'>
                  {leadSourceOptionWordOfMouth}
                </option>
                <option key='events' value='events'>
                  {leadSourceOptionEvents}
                </option>
                <option key='other' value='other'>
                  {leadSourceOptionOther}
                </option>
              </FieldSelect>
            </div>

            <div className={css.bottomWrapper}>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupExtraForm.continueButton"/>
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupExtraFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupExtraFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupExtraForm = compose(injectIntl)(SignupExtraFormComponent);
SignupExtraForm.displayName = 'SignupExtraForm';

export default SignupExtraForm;
