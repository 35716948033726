import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { createSlug } from '../../util/urlHelpers';
import { ExternalLink } from '../../components';

import css from './SectionExplore.module.css';

import arrowImage from './images/arrow.png';
import blogPostImage1 from './images/blog_post1.jpg';
import blogPostImage2 from './images/blog_post2.jpg';
import blogPostImage3 from './images/blog_post3.jpg';

class BlogPostImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(BlogPostImage);

const locationLink = ( title, description, link, image ) => {
  const titleText = <span className={css.blogPostTitle}>{title}</span>;

  return (
    <ExternalLink key={createSlug( title )} href={link} className={css.blogPost}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={title} className={css.blogPostImage} />
        </div>
      </div>
      <div className={css.linkText}>
        { titleText }
      </div>
      <div className={css.blogPostDescription}>
        {description}
      </div>
      <div className={css.blogPostLinkLine}>
        <div className={css.blogPostLink}>
          <FormattedMessage id="SectionExplore.readMore" />
          <img src={arrowImage} />
        </div>
      </div>
    </ExternalLink>
  );
};

const SectionExplore = props => {
  const { rootClassName, className, intl, isMobileLayout } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.titleLine}>
        <div className={css.title}>
          <FormattedMessage id="SectionExplore.title" />
        </div>
        <ExternalLink key="blogPosts" href="https://mentorme.io/blog/" className={css.actionButton}>
          <FormattedMessage id="SectionExplore.actionButton" />
        </ExternalLink>
      </div>
      <div className={css.blogPosts}>
        {locationLink(
          intl.formatMessage({ id: "SectionExplore.blogPostTitle1"}),
          intl.formatMessage({ id: "SectionExplore.blogPostText1"}),
          intl.formatMessage({ id: "SectionExplore.blogPostLink1"}),
          blogPostImage1,
        )}
        { !isMobileLayout ? (
          <>
            {locationLink(
              intl.formatMessage({ id: "SectionExplore.blogPostTitle2"}),
              intl.formatMessage({ id: "SectionExplore.blogPostText2"}),
              intl.formatMessage({ id: "SectionExplore.blogPostLink2"}),
              blogPostImage2,
            )}
            {locationLink(
              intl.formatMessage({ id: "SectionExplore.blogPostTitle3"}),
              intl.formatMessage({ id: "SectionExplore.blogPostText3"}),
              intl.formatMessage({ id: "SectionExplore.blogPostLink3"}),
              blogPostImage3,
            )}
          </>
        ) : null }
      </div>
    </div>
  );
};

SectionExplore.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionExplore.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionExplore;
