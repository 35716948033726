import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { formatMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';


import { NamedLink } from '../../components';

import css from './SectionBusinessMentors.module.css';

import starImage from './images/star.png';
import activeDesktopArrowLeftImage from './images/active_desktop_arrow_left.png';
import activeDesktopArrowRightImage from './images/active_desktop_arrow_right.png';
import activeMobileArrowLeftImage from './images/active_mobile_arrow_left.png';
import activeMobileArrowRightImage from './images/active_mobile_arrow_right.png';

class ListingImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage);

const locationLink = ( intl, title, image, id, authorName, price, listingType, averageRating, numberOfReviews ) => {
  const titleText = <span className={css.listingTitle}>{title}</span>;
  const typeText =
    <FormattedMessage
      id={
        listingType === 'course' ?
          "SectionSideHustles.perPackage" :
          "SectionSideHustles.perHour"
      }
    />;

  return (
    <NamedLink name="ListingPage" params={{ id, slug: createSlug( title )}} className={css.listing}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={title} className={css.listingImage} />
        </div>
      </div>
      <div className={css.linkText}>
        { titleText }
      </div>
      <div className={css.authorTitle}>
        <FormattedMessage
          id="SectionBusinessMentors.authorTitle"
          values={{ mentorName: authorName }}
        />
      </div>
      <div className={css.priceReviewLine}>
        <div className={css.listingPrice}>
          {formatMoney( intl, price )}
        </div>
        <div className={css.ratingValue}>
          <img src={starImage} className={css.starImage} />
          {averageRating}
        </div>
      </div>
      <div className={css.priceReviewLine}>
        <div className={css.ratingValue}>{typeText}</div>
        <div className={css.ratingValue}>{numberOfReviews}</div>
      </div>
    </NamedLink>
  );
};

const SectionBusinessMentors = props => {
  const [ firstListingInd, setFirstListingInd ] = useState( 0 );

  const { rootClassName, className, intl, isMobileLayout, listings } = props;
  const classes = classNames(rootClassName || css.root, className);
  const numberOfVisibleListings = isMobileLayout ? 1 : 5;
  const visibleCoreListings = listings.filter(( listing, index ) =>
    index >= firstListingInd && index < firstListingInd + numberOfVisibleListings
  );
  const visiblePrefixListings = firstListingInd < 0 ?
    listings.filter(( profile, index ) =>
      index >= listings.length + firstListingInd
    ) : [];
  const visibleSuffixListings = firstListingInd > listings.length - numberOfVisibleListings ?
    listings.filter(( profile, index ) =>
      index < firstListingInd + numberOfVisibleListings - listings.length
    ) : [];
  const visibleListings = [
    ...visiblePrefixListings, ...visibleCoreListings, ...visibleSuffixListings
  ];

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage
          id="SectionBusinessMentors.title"
          values={{
            mentors: (
              <span className={css.titleListings} >
                <FormattedMessage id="SectionBusinessMentors.titleMentors" />
              </span>
            )
          }}
        />
      </div>
      <div className={css.listings}>
        <img
          src={activeDesktopArrowLeftImage}
          className={css.activeArrowDesktopLeftImage}
          onClick={() => setFirstListingInd(
            firstListingInd > -numberOfVisibleListings ?
              firstListingInd - 1 :
              listings.length - numberOfVisibleListings
          )}
        />
        { visibleListings.map( listing => {
          const authorPublicData = listing.author.attributes.profile.publicData || {};
          const { numberOfReviews = 0, averageRating = 0 } = authorPublicData;
          const listingPublicData = listing.attributes.publicData || {};
          const { type: listingType } = listingPublicData;

          return locationLink(
            intl,
            listing.attributes.title,
            listing.images[0].attributes.variants.default.url,
            listing.id.uuid,
            listing.author.attributes.profile.displayName,
            listing.attributes.price,
            listingType,
            Math.round( averageRating / 100 ) / 10,
            numberOfReviews
          )
        })}
        <img
          src={activeDesktopArrowRightImage}
          className={css.activeArrowDesktopRightImage}
          onClick={
            () => setFirstListingInd( firstListingInd < listings.length ? firstListingInd + 1 : 0 )
          }
        />
      </div>
      <div className={css.arrowMobileRow}>
        <img
          src={activeMobileArrowLeftImage}
          className={css.activeArrowMobileLeftImage}
          onClick={() => setFirstListingInd(
            firstListingInd > -numberOfVisibleListings ?
              firstListingInd - 1 :
              listings.length - numberOfVisibleListings
          )}

        />
        <img
          src={activeMobileArrowRightImage}
          className={css.activeArrowMobileRightImage}
          onClick={
            () => setFirstListingInd( firstListingInd < listings.length ? firstListingInd + 1 : 0 )
          }
        />
      </div>
    </div>
  );
};

SectionBusinessMentors.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionBusinessMentors.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBusinessMentors;
