import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingTimelineForm } from '../../forms';

import css from './EditListingTimelinePanel.module.css';

const EditListingTimelinePanel = props => {
  const {
    className,
    rootClassName,
    currentUser,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    onUpdateTimelineAvailability,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const firstName = currentUser.attributes.profile.firstName;
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingTimelinePanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage
      id="EditListingTimelinePanel.createListingTitle"
    />
  );

  const { lessons = []} = publicData;
  const lessonFields = {};

  lessons.forEach(( lesson, ind ) => {
    lessonFields[`lessonDate${ ind + 1 }`] = lesson.lessonDate;
    lessonFields[`lessonTitle${ ind + 1 }`] = lesson.lessonTitle;
    lessonFields[`lessonStartTime${ ind + 1 }`] = lesson.lessonStartTime;
    lessonFields[`lessonEndTime${ ind + 1 }`] = lesson.lessonEndTime;
  });

  const getStartDate = ( values, numberOfLessons ) => {
    let startDate = new Date( values['lessonDate1']);

    for( let lessonInd = 1; lessonInd <= numberOfLessons; lessonInd++ ){
      const currentDate = new Date( values[`lessonDate${lessonInd}`]);

      if( currentDate.getTime() <= startDate.getTime()){
        startDate = currentDate;
      }
    }

    return startDate.getTime();
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingTimelineForm
        className={css.form}
        firstName={firstName}
        numberOfLessons={lessons.length}
        initialValues={{ numberOfSeats: publicData.numberOfSeats, ...lessonFields }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { numberOfSeats } = values;
          let numberOfLessons = 1;

          Object.keys( values ).forEach( valueName => {
            if( valueName.indexOf('lessonDate') === 0 ){
              const lessonInd = Number.parseInt( valueName.replace('lessonDate', ''));

              if( numberOfLessons < lessonInd ){
                numberOfLessons = lessonInd;
              }
            }
          });

          const lessons = [];
          for( let lInd = 1; lInd <= numberOfLessons; lInd++ ){
            lessons.push({
              lessonDate: values[`lessonDate${lInd}`],
              lessonTitle: values[`lessonTitle${lInd}`],
              lessonStartTime: values[`lessonStartTime${lInd}`],
              lessonEndTime: values[`lessonEndTime${lInd}`],
            });
          }

          const startDate = getStartDate( values, numberOfLessons );
          const updateValues = {
            publicData: {
              numberOfSeats,
              lessons,
              startDate,
            }
          };

          onUpdateTimelineAvailability({ listingId: listing.id, startDate, numberOfSeats });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingTimelinePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingTimelinePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingTimelinePanel;
