import pick from 'lodash/pick';
import config from '../../config';
import {
  initiatePrivileged,
  // initiateClearpayPrivileged,
  transitionPrivileged,
  profileInitiatePrivileged,
  // profileInitiateClearpayPrivileged,
  profileTransitionPrivileged,
  createAnotherPaymentMethodIntent,
  retrievePaymentIntent,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const CREATE_CLEARPAY_REQUEST = 'app/CheckoutPage/CREATE_CLEARPAY_REQUEST';
export const CREATE_CLEARPAY_SUCCESS = 'app/CheckoutPage/CREATE_CLEARPAY_SUCCESS';
export const CREATE_CLEARPAY_ERROR = 'app/CheckoutPage/CREATE_CLEARPAY_ERROR';

export const RETRIEVE_PAYMENT_INTENT_REQUEST = 'app/CheckoutPage/RETRIEVE_PAYMENT_INTENT_REQUEST';
export const RETRIEVE_PAYMENT_INTENT_SUCCESS = 'app/CheckoutPage/RETRIEVE_PAYMENT_INTENT_SUCCESS';
export const RETRIEVE_PAYMENT_INTENT_ERROR = 'app/CheckoutPage/RETRIEVE_PAYMENT_INTENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  createClearpayInProgress: false,
  createClearpaySuccess: false,
  createClearpayError: null,

  paymentIntent: null,
  retrievePaymentIntentInProgress: false,
  retrievePaymentIntentError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case CREATE_CLEARPAY_REQUEST:
      return {
        ...state,
        createClearpayInProgress: true,
        createClearpayError: null,
      };
    case CREATE_CLEARPAY_SUCCESS:
      return {
        ...state,
        transaction: payload,
        createClearpayInProgress: false,
        createClearpaySuccess: true,
      };
    case CREATE_CLEARPAY_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        createClearpayInProgress: false,
        createClearpaySuccess: false,
        createClearpayError: payload,
      };

    case RETRIEVE_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        retrievePaymentIntentError: null,
        retrievePaymentIntentInProgress: true,
      };
    case RETRIEVE_PAYMENT_INTENT_SUCCESS:
      return { ...state, paymentIntent: payload, retrievePaymentIntentInProgress: false };
    case RETRIEVE_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        retrievePaymentIntentError: payload,
        retrievePaymentIntentInProgress: false,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => {
  return {
    type: SET_INITIAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState)),
  };
};

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

const createClearpayRequest = () => ({ type: CREATE_CLEARPAY_REQUEST });

const createClearpaySuccess = stripeTxId => ({
  type: CREATE_CLEARPAY_SUCCESS,
  payload: stripeTxId,
});

const createClearpayError = e => ({
  type: CREATE_CLEARPAY_ERROR,
  error: true,
  payload: e,
});

export const retrievePaymentIntentRequest = () => ({
  type: RETRIEVE_PAYMENT_INTENT_REQUEST,
});

export const retrievePaymentIntentSuccess = payload => ({
  type: RETRIEVE_PAYMENT_INTENT_SUCCESS,
  payload,
});

export const retrievePaymentIntentError = payload => ({
  type: RETRIEVE_PAYMENT_INTENT_ERROR,
  payload,
  error: true,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  const { paymentMethod, ...restParams } = orderParams?.anotherPaymentParams ?? {};

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    bookVettingAnswers: orderParams.bookVettingAnswers,
  };

  let processAlias;
  if (orderParams.listingType === 'profile') {
    if (paymentMethod === 'klarnaForm' || paymentMethod === 'clearpayForm') {
      processAlias = config.profileClearpayProcessAlias;
    } else {
      processAlias = config.profileBookingProcessAlias;
    }
  } else {
    if (paymentMethod === 'klarnaForm' || paymentMethod === 'clearpayForm') {
      processAlias = config.courseClearpayProcessAlias;
    } else {
      processAlias = config.courseBookingProcessAlias;
    }
  }

  // orderParams.listingType === 'profile'
  //   ? config.profileBookingProcessAlias
  //   : config.courseBookingProcessAlias;
  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  let tmpOrder;
  const handleSucces = response => {
    if (paymentMethod === 'klarnaForm' || paymentMethod === 'clearpayForm') {
      tmpOrder.data.data.attributes.protectedData.stripePaymentIntents = {
        default: {
          stripePaymentIntentClientSecret: response.intent.client_secret,
          stripePaymentIntentId: response.intent.id,
        },
      };
      response = tmpOrder;
    }

    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  const getAnotherPaymentIntent = response => {
    if (paymentMethod === 'klarnaForm' || paymentMethod === 'clearpayForm') {
      tmpOrder = response;

      restParams.lineItems = response.data.data.attributes.lineItems ?? [];
      restParams.transactionId = response.data.data.id.uuid ?? null;

      if (!restParams.amount) {
        restParams.amount = response.data.data.attributes.payinTotal.amount;
      }

      return createAnotherPaymentMethodIntent(restParams);
    }
    return response;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    const transitionFunction =
      orderParams.listingType === 'profile' ? profileTransitionPrivileged : transitionPrivileged;

    return transitionFunction({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    const initiateFunction =
      orderParams.listingType === 'profile' ? profileInitiatePrivileged : initiatePrivileged;

    return initiateFunction({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(getAnotherPaymentIntent)
      .then(handleSucces)
      .catch(handleError);
  } else {
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(getAnotherPaymentIntent)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: { bookingStart: orderParams.bookingStart, bookingEnd: orderParams.bookingEnd },
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
  };

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const processAlias =
    orderParams.listingType === 'profile'
      ? config.profileBookingProcessAlias
      : config.courseBookingProcessAlias;

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    const transitionFunction =
      orderParams.listingType === 'profile' ? profileTransitionPrivileged : transitionPrivileged;

    return transitionFunction({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    const initiateFunction =
      orderParams.listingType === 'profile' ? profileInitiatePrivileged : initiatePrivileged;

    return initiateFunction({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const getPaymentIntent = paymentIntent => dispatch => {
  dispatch(retrievePaymentIntentRequest());

  return retrievePaymentIntent({ paymentIntentId: paymentIntent })
    .then(response => {
      dispatch(retrievePaymentIntentSuccess(response.intent));

      return response.intent;
    })
    .catch(e => {
      dispatch(retrievePaymentIntentError(storableError(e)));
    });
};

export const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return response.data.data;
  });
};

// export const createClearpayTransaction = stripeTxId => (dispatch, getState, sdk) => {
//   dispatch(createClearpayRequest());

//   let stripeTxProtectedData = { clearpayPaymentIntent: {}, bookingData: {} };
//   let orderParams = {};
//   let listing = null;

//   sdk.transactions
//     .transition(
//       {
//         id: new UUID(stripeTxId),
//         transition: 'transition/cancel-payment',
//         params: {},
//       },
//       { expand: true, include: ['listing'] }
//     )
//     .then(stripeTxRes => {
//       stripeTxProtectedData = stripeTxRes.data.data.attributes.protectedData;
//       listing = stripeTxRes.data.included[0];
//       const listingType = listing.attributes.publicData.type;
//       // Here we use tx processes without flex stripe actions
//       const processAlias =
//         listingType === 'profile'
//           ? config.profileClearpayProcessAlias
//           : config.courseClearpayProcessAlias;
//       const bookingData = {
//         startDate: new Date(stripeTxProtectedData.bookingData.startDate),
//         endDate: new Date(stripeTxProtectedData.bookingData.endDate),
//       };
//       orderParams = {
//         listingId: listing.id,
//         bookingStart: bookingData.startDate,
//         bookingEnd: bookingData.endDate,
//         protectedData: {
//           clearpayPaymentIntent: stripeTxProtectedData.clearpayPaymentIntent,
//           bookVettingAnswers: stripeTxProtectedData.bookVettingAnswers,
//         },
//       };
//       const bodyParams = {
//         processAlias,
//         transition: 'transition/confirm-payment',
//         params: orderParams,
//       };
//       const queryParams = {
//         include: ['booking', 'provider'],
//         expand: true,
//       };

//       const initiateFunction =
//         listingType === 'profile' ? profileInitiateClearpayPrivileged : initiateClearpayPrivileged;

//       return initiateFunction({ isSpeculative: true, bookingData, bodyParams, queryParams });
//     })
//     .then(clearpayRes => {
//       const entities = denormalisedResponseEntities(clearpayRes);
//       const order = entities[0];

//       dispatch(createClearpaySuccess(order));
//       dispatch(fetchCurrentUserHasOrdersSuccess(true));

//       return order;
//     })
//     .catch(e => {
//       dispatch(createClearpayError(storableError(e)));

//       log.error(e, 'initiate-order-failed', {
//         stripeTxId,
//         listingId: listing.id.uuid,
//         bookingStart: orderParams.bookingStart,
//         bookingEnd: orderParams.bookingEnd,
//       });
//       throw e;
//     });
// };
