import { storableError } from '../../util/errors';
import { adminFetchReferralsData, adminFetchRefereeValues } from '../../util/api';
import pick from 'lodash/pick';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/AdminManageReferralsPage/SET_INITIAL_VALUES';

export const FETCH_USERS_REQUEST = 'app/AdminManageReferralsPage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'app/AdminManageReferralsPage/FETCH_USERS_SUCCESS';
export const FETCH_USER_REFEREE_VALUES_SUCCESS = 'app/AdminManageReferralsPage/FETCH_USER_REFEREE_VALUES_SUCCESS';
export const FETCH_USERS_ERROR = 'app/AdminManageReferralsPage/FETCH_USERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  fetchUsersInProgress: false,
  fetchUsersError: null,
  users: [],
  refereeValues: {},
};

const updateUserAttributes = ( users, user ) => {
  const updatedUsers = [];
  let userIndex = -1;

  users.forEach(( curUser, index ) => {
    if( user.id !== curUser.id ){
      updatedUsers.push( curUser );
    } else {
      userIndex = index;
    }
  });

  if( userIndex === -1 ) {
    updatedUsers.push(user);
  } else {
    updatedUsers.splice( userIndex, 0, user );
  }

  return updatedUsers;
};

const adminManageReferralsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_USERS_REQUEST:
      return {
        ...state,
        fetchUsersInProgress: true,
        fetchUsersError: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users,
        pagination: payload.meta,
        fetchUsersInProgress: false,
      };
    case FETCH_USER_REFEREE_VALUES_SUCCESS:
      return {
        ...state,
        refereeValues: payload.refereeValues,
      };
    case FETCH_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchUsersInProgress: false, fetchUsersError: payload };

    default:
      return state;
  }
};

export default adminManageReferralsPageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
  payload: {},
});

export const fetchUsersSuccess = response => ({
  type: FETCH_USERS_SUCCESS,
  payload: response,
});

export const fetchUserRefereeValuesSuccess = response => ({
  type: FETCH_USER_REFEREE_VALUES_SUCCESS,
  payload: response,
});

export const fetchUsersError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

export const fetchUsers = page => (dispatch, getState, sdk) => {
  dispatch( fetchUsersRequest());

  return adminFetchReferralsData( page )
    .then( response => {
      const userIds = response && Array.isArray( response.users ) && response.users.length > 0 ?
        response.users.map( user => user.id ) : [];

      dispatch( fetchUsersSuccess( response ));

      if( userIds.length > 0 )
        return adminFetchRefereeValues({ userIds });

      return Promise.resolve({ refereeValues: {}});
    })
    .then( response => {
      dispatch( fetchUserRefereeValuesSuccess( response ));

      return response;
    })
    .catch(e => {
      dispatch( fetchUsersError( storableError(e)));

      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return dispatch( fetchUsers( 1 ));
};
