import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { findOptionsForSelectFilter } from '../../util/search';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../components';

import css from './VettingPart1Form.module.css';
import config from '../../config';

const VettingPart1FormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
      } = formRenderProps;

      const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

      // Business background
      const businessBackgroundLabel = intl.formatMessage({
        id: 'VettingPart1Form.businessBackgroundLabel',
      });
      const businessBackgroundPlaceholder = intl.formatMessage({
        id: 'VettingPart1Form.businessBackgroundPlaceholder',
      });
      const businessBackgroundRequiredMessage = intl.formatMessage({
        id: 'VettingPart1Form.businessBackgroundRequired',
      });
      const businessBackgroundRequired = validators.required(businessBackgroundRequiredMessage);

      // Ownership type
      const ownershipTypeLabel = intl.formatMessage({
        id: 'VettingPart1Form.ownershipTypeLabel',
      });
      const ownershipTypePlaceholder = intl.formatMessage({
        id: 'VettingPart1Form.ownershipTypePlaceholder',
      });
      const ownershipTypeOptionLimitedCompany = intl.formatMessage({
        id: 'VettingPart1Form.ownershipTypeOptionLimitedCompany',
      });
      const ownershipTypeOptionOther = intl.formatMessage({
        id: 'VettingPart1Form.ownershipTypeOptionOther',
      });
      const ownershipTypeOptionSoleTrader = intl.formatMessage({
        id: 'VettingPart1Form.ownershipTypeOptionSoleTrader',
      });
      const ownershipTypeRequiredMessage = intl.formatMessage({
        id: 'VettingPart1Form.ownershipTypeRequired',
      });
      const ownershipTypeRequired = validators.required(ownershipTypeRequiredMessage);

      // Company name
      const companyNameLabel = intl.formatMessage({
        id: 'VettingPart1Form.companyNameLabel',
      });
      const companyNamePlaceholder = intl.formatMessage({
        id: 'VettingPart1Form.companyNamePlaceholder',
      });
      const companyNameRequiredMessage = intl.formatMessage({
        id: 'VettingPart1Form.companyNameRequired',
      });
      const companyNameRequired = validators.required(companyNameRequiredMessage);

      // Trading start date
      const tradingStartDateLabel = intl.formatMessage({
        id: 'VettingPart1Form.tradingStartDateLabel',
      });
      const tradingStartDatePlaceholder = intl.formatMessage({
        id: 'VettingPart1Form.tradingStartDatePlaceholder',
      });
      const tradingStartDateRequiredMessage = intl.formatMessage({
        id: 'VettingPart1Form.tradingStartDateRequired',
      });
      const tradingStartDateRequired = validators.required(tradingStartDateRequiredMessage);

      // Business address
      const businessAddressLabel = intl.formatMessage({
        id: 'VettingPart1Form.businessAddressLabel',
      });
      const businessAddressPlaceholder = intl.formatMessage({
        id: 'VettingPart1Form.businessAddressPlaceholder',
      });
      const businessAddressRequiredMessage = intl.formatMessage({
        id: 'VettingPart1Form.businessAddressRequired',
      });
      const businessAddressRequired = validators.required(businessAddressRequiredMessage);

      // Service type
      const serviceTypeLabel = intl.formatMessage({
        id: 'VettingPart1Form.serviceTypeLabel',
      });
      const serviceTypePlaceholder = intl.formatMessage({
        id: 'VettingPart1Form.serviceTypePlaceholder',
      });
      const serviceTypeRequiredMessage = intl.formatMessage({
        id: 'VettingPart1Form.serviceTypeRequired',
      });
      const serviceTypeRequired = validators.required(serviceTypeRequiredMessage);

      // Coaching type
      const coachingTypeLabel = intl.formatMessage({
        id: 'VettingPart1Form.coachingTypeLabel',
      });
      const coachingTypePlaceholder = intl.formatMessage({
        id: 'VettingPart1Form.coachingTypePlaceholder',
      });
      const coachingTypeOptionOneToOne = intl.formatMessage({
        id: 'VettingPart1Form.coachingTypeOptionOneToOne',
      });
      const coachingTypeOptionLongTerm = intl.formatMessage({
        id: 'VettingPart1Form.coachingTypeOptionLongTerm',
      });
      const coachingTypeOptionBoth = intl.formatMessage({
        id: 'VettingPart1Form.coachingTypeOptionBoth',
      });
      const coachingTypeRequiredMessage = intl.formatMessage({
        id: 'VettingPart1Form.coachingTypeRequired',
      });
      const coachingTypeRequired = validators.required(coachingTypeRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="textarea"
              id={formId ? `${formId}.businessBackground` : 'businessBackground'}
              name="businessBackground"
              className={css.businessBackgroundInput}
              label={businessBackgroundLabel}
              placeholder={businessBackgroundPlaceholder}
              validate={businessBackgroundRequired}
            />
            <FieldSelect
              id="ownershipType"
              name="ownershipType"
              className={css.ownershipTypeInput}
              label={ownershipTypeLabel}
              validate={ownershipTypeRequired}
            >
              <option disabled value="">
                {ownershipTypePlaceholder}
              </option>
              <option value="limited-company">
                {ownershipTypeOptionLimitedCompany}
              </option>
              <option value="sole-trader">
                {ownershipTypeOptionSoleTrader}
              </option>
              <option value="other">
                {ownershipTypeOptionOther}
              </option>
            </FieldSelect>
          </div>
          <FieldTextInput
            type="text"
            id={formId ? `${formId}.companyName` : 'companyName'}
            name="companyName"
            className={css.companyNameInput}
            label={companyNameLabel}
            placeholder={companyNamePlaceholder}
            validate={companyNameRequired}
          />
          <FieldTextInput
            type="date"
            id={formId ? `${formId}.tradingStartDate` : 'tradingStartDate'}
            name="tradingStartDate"
            className={css.tradingStartDateInput}
            label={tradingStartDateLabel}
            placeholder={tradingStartDatePlaceholder}
            validate={tradingStartDateRequired}
          />
          <FieldTextInput
            type="text"
            id={formId ? `${formId}.businessAddress` : 'businessAddress'}
            name="businessAddress"
            className={css.businessAddressInput}
            label={businessAddressLabel}
            placeholder={businessAddressPlaceholder}
            validate={businessAddressRequired}
          />
          <FieldSelect
            id="serviceType"
            name="serviceType"
            className={css.serviceTypeInput}

            label={serviceTypeLabel}
            validate={serviceTypeRequired}
          >
            <option disabled value="">
              {serviceTypePlaceholder}
            </option>
            {categoryOptions.map( option => (
              <option key={option.key} value={option.key}>
                {option.label}
              </option>
            ))}
          </FieldSelect>
          <FieldSelect
            id="coachingType"
            name="coachingType"
            className={css.coachingTypeInput}
            label={coachingTypeLabel}
            validate={coachingTypeRequired}
          >
            <option disabled value="">
              {coachingTypePlaceholder}
            </option>
            <option value="one-to-one">
              {coachingTypeOptionOneToOne}
            </option>
            <option value="long-term">
              {coachingTypeOptionLongTerm}
            </option>
            <option value="both">
              {coachingTypeOptionBoth}
            </option>
          </FieldSelect>

          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="VettingPart1Form.continue" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

VettingPart1FormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

VettingPart1FormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const VettingPart1Form = compose(injectIntl)(VettingPart1FormComponent);
VettingPart1Form.displayName = 'VettingPart1Form';

export default VettingPart1Form;
