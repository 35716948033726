import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionAboutHero.module.css';

const SectionAboutHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, heroImageIndex = 1 } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage
            id="SectionAboutHero.title"
            values={{
              story:
                <span className={css.storyMainTitle}>
                  <FormattedMessage id="SectionAboutHero.titleStory" />
                </span>
            }}
          />
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionAboutHero.subtitle" />
        </h2>
      </div>
    </div>
  );
};

SectionAboutHero.defaultProps = { rootClassName: null, className: null };

SectionAboutHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAboutHero;
