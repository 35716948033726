/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        //{ key: 'fulfilment_by_amazon', label: 'Fulfilment by Amazon' },
        {
          key: 'e_commerce',
          label: 'E-Commerce',
          description: 'What is E-Commerce? Well it\'s the future of shopping! It\'s the buying and selling of products or services over the internet. Our expert mentors will show you how to make it simple and fun with Shopify, Amazon FBA, Dropshipping, or Personal Shopping. Contact a mentor to start your journey and discover the world of E-Commerce today!',
          blog_title_1: 'Where are Amazon Fulfilment Centres in the UK?',
          blog_link_1: 'https://mentorme.io/blog/amazon-fulfilment-centres-uk/',
          blog_title_2: 'Is E-commerce Legit?',
          blog_link_2: 'https://mentorme.io/blog/is-e-commerce-legit/',
          blog_title_3: 'Best Website Platforms for E-commerce',
          blog_link_3: 'https://mentorme.io/blog/best-website-platforms-for-e-commerce/',
        },
        {
          key: 'trading',
          label: 'Trading & Investing',
          description: 'Step into the world of wealth-building! Trading & Investing is all about buying and selling financial assets such as Stocks, Crypto, Forex, and Derivatives with the aim of growing your wealth. Our expert mentors are here to show you the ropes and make this journey exciting and fun. Learn the fundamentals and psychological aspects of trading financial instruments. Contact a mentor to start your journey and discover the world of trading & investing today!',
          blog_title_1: 'Starting a Trading Career',
          blog_link_1: 'https://mentorme.io/blog/starting-a-trading-career/',
          blog_title_2: 'Coming soon...',
          blog_title_3: 'Coming soon...',
        },
        {
          key: 'property',
          label: 'Property',
          description: 'Invest in property, the future of wealth! Property refers to real estate, buildings, and land. Our expert mentors will show you the different strategies like HMOs, R2R, SA, BRRRR and Flips. Learn how to make property work for you and build long-term wealth. Contact a mentor to start your journey and discover the world of property today!',
          blog_title_1: 'Property Buying & Sales',
          blog_link_1: 'https://mentorme.io/blog/property-buying-and-sales/',
          blog_title_2: 'Coming soon...',
          blog_title_3: 'Coming soon...',
        },
        {
          key: 'business_coaching',
          label: 'Business Coaching',
          description: 'Unlock your business potential with a coach! A business coach is a guide who helps entrepreneurs and business owners achieve their goals through leadership coaching, mindset work, life coaching, and social media strategies. Our expert mentors are here to help you unlock your full potential and take your business to new heights. Join us and let\'s turn your vision into reality with the help of a knowledgeable business coach. Contact a mentor to start or continue your journey and to business success today!',
          blog_title_1: 'How to find a mentor for your career',
          blog_link_1: 'https://mentorme.io/blog/how-to-find-a-mentor/',
          blog_title_2: 'Coming soon...',
          blog_title_3: 'Coming soon...',
        },
        {
          key: 'other',
          label: 'Other',
          description: '',
          blog_title_1: 'Coming soon...',
          blog_title_2: 'Coming soon...',
          blog_title_3: 'Coming soon...',
        },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Expertise',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 25000,
      step: 5,
    },
  },
  {
    id: 'listing_type',
    label: 'Listing type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_type'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        { key: 'profile', label: 'Hourly' },
        { key: 'course', label: 'Packages' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    { key: 'pub_averageRating', label: 'Highest rating' },
    { key: '-pub_averageRating', label: 'Lowest rating' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const frequentlyAskedQuestions = {
  e_commerce: {
    label: "E-Commerce",
    questions: {
      question_e_commerce_1: {
        question: "What is E-Commerce?",
        answer: "E-Commerce is the buying and selling of products or services over the internet, businesses such as AirBnB, Amazon, eBay, Shopify are all E-Commerce businesses. ",
      },
      question_e_commerce_2: {
        question: "What is the difference between Dropshipping & FBA?",
        answer: "Dropshipping & FBA are two of the most popular E-Commerce methods. Dropshipping refers to the selling of products without holding the stock, you would usually make the sale first then buy the stock and ship direct. Dropshipping is commonly used on Shopify stores & eBay. Whereas FBA (Fulfilment by Amazon) refers to the selling of products via Amazon’s FBA services. You buy stock and send it into an Amazon fulfilment warehouse where is it packed and shipped via Prime to customers that purchase through Amazon.",
      },
      question_e_commerce_3: {
        question: "What does an E-Commerce Mentor do?",
        answer: "Our E-Commerce mentors can all teach you different strategies from Amazon FBA Wholesale & Private Label to Shopify Dropshipping. The most important thing our mentors do is provide you with live mentoring and allow you to ask and have questions answered. Alongside the mentoring, the mentors also run successful E-Commerce businesses, as this is a pre-requisite to listing on MentorME.",
      },
      question_e_commerce_4: {
        question: "How can I refer an E-Commerce Mentor?",
        answer: "If you think you know a great E-Commerce mentor who is actively running a successful E-Commerce business alongside mentoring, invite them to sign up utilising your unique referral link – you can find your referral link here: https://mentorme.io/referrals",
      },
      question_e_commerce_5: {
        question: "How do you vet E-Commerce Mentors?",
        answer: "All of our E-Commerce mentors have to pass a 2 step process as well as confirm their identity. This 2 step process includes a questionnaire, which allows us to get some information about their background, their experience, and the mentorship. The 2nd step is a Zoom call, so that we can dive deeper into their background and understand exactly how the mentorship works. If a mentor does not run a successful E-Commerce business, they will not be on our platform. If a mentorship programme is exclusively pre-recordings, they will not be listed on our platform.",
      },
    }
  },

  property: {
    label: "property",
    questions: {
      question_real_estate_1: {
        question: "What is Property Investing?",
        answer: "Property investing is the purchasing of Property with the aim to make a return. Buying property and converting into HMOs is one form of property investment.",
      },
      question_real_estate_2: {
        question: "What are some of the different property strategies?",
        answer: "There are many different strategies in Property, some of the most notable are: HMOs (Houses of Multiple Occupancy), BRRRR (Buy, Refurbish, Rent, Refinance, Repeat), R2R (Rent to Rent), SA (Service Accommodation) & then there are also other strategies that do not involve buying properties such as deal sourcing.",
      },
      question_real_estate_3: {
        question: "What does a Property Mentor do?",
        answer: "Our Property mentors can all teach you different strategies from HMOs, R2R, BRRRR to SA & Deal Sourcing. The most important thing our mentors do is provide you with live mentoring and allow you to ask and have questions answered. Alongside the mentoring, the mentors also run successful Property businesses, as this is a pre-requisite to listing on MentorME.",
      },
      question_real_estate_4: {
        question: "How can I refer a Property Mentor?",
        answer: "If you think you know a great Property mentor who is actively running a successful Property business alongside mentoring, invite them to sign up utilising your unique referral link – you can find your referral link here: https://mentorme.io/referrals",
      },
      question_real_estate_5: {
        question: "How do you vet Property Mentors?",
        answer: "All of our Property mentors have to pass a 2 step process as well as confirm their identity. This 2 step process includes a questionnaire, which allows us to get some information about their background, their experience, and the mentorship. The 2nd step is a Zoom call, so that we can dive deeper into their background and understand exactly how the mentorship works. If a mentor does not run a successful Property business, they will not be on our platform. If a mentorship programme is exclusively pre-recordings, they will not be listed on our platform.",
      },
    }
  },

  trading: {
    label: "Trading & Investing",
    questions: {
      question_trading_1: {
        question: "What is Trading & Investing?",
        answer: "Trading is the purchasing & selling of financial instruments in the hope of making a profit by taking advantage of price movements in financial markets. Investing, on the other hand, refers to the process of putting money into financial assets with the goal of achieving long-term growth or income. Investing often involves a longer time horizon and a more buy-and-hold strategy, whereas trading is focused on more short-term profits.",
      },
      question_trading_2: {
        question: "What are some of the different financial instruments?",
        answer: "There are many different types of financial instruments, here are just a few: stocks, which represent ownership stakes in publicly traded companies; derivatives, financial products that derive their value from an underlying asset; cryptocurrency, a digital or virtual currency secured by cryptography; and FX/Forex (foreign exchange), the buying and selling of currencies in the world's largest and most liquid financial market. Whether you're looking for short-term gains through trading or long-term growth through investing, there's a financial instrument to suit your needs.",
      },
      question_trading_3: {
        question: "What does a Trading & Investing Mentor do?",
        answer: "Our Trading & Investing mentors can all teach you different strategies from Stocks & Forex to Derivatives & Crypto. The most important thing our mentors do is provide you with live mentoring and allow you to ask and have questions answered. Alongside the mentoring, the mentors are also actively trading the markets successfully, as this is a pre-requisite to listing on MentorME.",
      },
      question_trading_4: {
        question: "How can I refer a Trading & Investing Mentor?",
        answer: "If you think you know a great Trading or Investing mentor who is actively trading the markets successfully alongside mentoring, invite them to sign up utilising your unique referral link – you can find your referral link here: https://mentorme.io/referrals",
      },
      question_trading_5: {
        question: "How do you vet Trading & Investing Mentors?",
        answer: "All of our Trading or Investing mentors have to pass a 2 step process as well as confirm their identity. This 2 step process includes a questionnaire, which allows us to get some information about their background, their experience, and the mentorship. The 2nd step is a Zoom call, so that we can dive deeper into their background and understand exactly how the mentorship works. If a mentor does not actively trade or invest, they will not be on our platform. If a mentorship programme is exclusively pre-recordings, they will not be listed on our platform.",
      },

    }
  },

  business_coaching: {
    label: "Business Coaching",
    questions: {
      question_business_coaching_1: {
        question: "What is Business Coaching?",
        answer: "Business coaching is like having a personal cheerleader for your company! It's a dynamic and empowering relationship where a coach works with individuals or organizations to unleash their full potential and achieve big wins in the world of business. With fresh perspectives, a wealth of experience, and an unwavering commitment to your success, a business coach will guide you to new heights, providing the support, guidance, and accountability you need to tackle any challenge and seize every opportunity. Whether you're looking to transform your leadership, supercharge your sales, streamline your operations, or simply take your business or career to the next level, business coaching is the ultimate boost to help you make it happen!",
      },
      question_business_coaching_2: {
        question: "What are some of the different business coaching categories available?",
        answer: "A business coach and mentor can operate in a lot of different sectors depending on their background. On MentorME, you will find a range of coaches from Social Media to Leadership Coaching, from Mindset coaching to Life Coaching. There are so many different options when choosing a business coach, so make to compare!",
      },
      question_business_coaching_3: {
        question: "What does a Business Coach do?",
        answer: "A business coach may provide guidance, support, and accountability, as well as offer new insights and strategies to improve various aspects of the business, such as leadership, marketing, sales, operations, and strategy. The goal of business coaching is to help clients achieve their desired outcomes, such as increased profits, improved productivity, and enhanced leadership skills. Business coaching is often customized to the specific needs of each client and may involve one-on-one sessions, team coaching, or group workshops.",
      },
      question_business_coaching_4: {
        question: "How can I refer a Business Coach or Mentor?",
        answer: "If you think you know a great Business Coach or mentor, invite them to sign up utilising your unique referral link – you can find your referral link here: https://mentorme.io/referrals",
      },
      question_business_coaching_5: {
        question: "How do you vet Business Coaches & Mentors?",
        answer: "All of our Business Coaches & mentors have to pass a 2 step process as well as confirm their identity. This 2 step process includes a questionnaire, which allows us to get some information about their background, their experience, and the mentorship. The 2nd step is a Zoom call, so that we can dive deeper into their background and understand exactly how the mentorship works. If a business coach does not have relevant experience in their subject matter, they will not list on our platform. If a mentorship programme is exclusively pre-recordings, they will not be listed on our platform.",
      },
    }
  }
};
