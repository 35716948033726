import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import {
  ListingCard,
  PaginationLinks,
  SectionCategories,
  SectionFaqContent,
  SectionSchedule
} from '../../components';
import SectionCategoryExplainer from './SectionCategoryExplainer';
import SectionCategoryOthers from './SectionCategoryOthers';
import css from './SearchResultsPanel.module.css';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    intl,
    category,
    categoryOptions,
    listings,
    pagination,
    search,
    setActiveListing
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const categoryOption = category ? categoryOptions.find( option => option.key === category ) : 0;
  const categoryHeaderBlock = category && category !== 'other' ? (
    <>
      <div className={css[`categoryTitleBlock_${categoryOption.key}`]}>
        <h1 className={css.categoryTitle}>
          <FormattedMessage id={`SearchResultsPanel.pageHeaderTitle_${categoryOption.key}`} />
        </h1>
        <div className={css.categoryDescription}>
          <FormattedMessage id={`SearchResultsPanel.pageHeaderText_${categoryOption.key}`} />
        </div>
      </div>
      <h2 className={css.categorySubTitle1}>
        <FormattedMessage id={`SearchResultsPanel.pageSubheaderTitle_${categoryOption.key}`} />
      </h2>
      <div className={css.categorySubDescription1}>
        <FormattedMessage id={`SearchResultsPanel.pageSubheaderText_${categoryOption.key}`} />
      </div>
      <div className={css.categoryExplore}>
        {`Explore ${categoryOption.label}`}
      </div>
    </>
  ) : null;
  const categoryFooterBlock = category ? (
    <>
      {category !== 'other' ?
        <SectionCategoryExplainer categoryOption={categoryOption}/> : null
      }
      <h2 className={css.categorySubTitle}>
        <FormattedMessage id={`SearchResultsPanel.pageSubheaderTitle3_${categoryOption.key}`} />
      </h2>
      <SectionCategories categoryOption={categoryOption} />
      <SectionFaqContent categoryOption={categoryOption} intl={intl} />
      <h2 className={css.categorySubTitle}>
        <FormattedMessage id={`SearchResultsPanel.pageSubheaderText3_${categoryOption.key}`} />
      </h2>
      <div className={css.scheduleSection}>
        <SectionSchedule rootClassName={css.scheduleRoot} />
      </div>
      <h2 className={css.categoryDiscoverTitle}>
        <FormattedMessage id="SearchResultsPanel.pageSubheaderDiscoverMore"/>
      </h2>
      <SectionCategoryOthers categoryOption={categoryOption} />
    </>
  ) : null;

  return (
    <div className={classes}>
      {categoryHeaderBlock}
      <div className={css.listingCards}>
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
      {categoryFooterBlock}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
