import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { NamedLink } from '../../components';

import css from './SectionDecideApply.module.css';

import desktopFooterImage1 from './images/desktop-footer1.jpg';
import desktopFooterImage2 from './images/desktop-footer2.jpg';
import desktopFooterImage3 from './images/desktop-footer3.jpg';

import mobileFooterImage1 from './images/mobile-footer1.jpg';
import mobileFooterImage2 from './images/mobile-footer2.jpg';
import mobileFooterImage3 from './images/mobile-footer3.jpg';

import explanationImage1 from './images/explanation1.jpg';
import explanationImage2 from './images/explanation2.jpg';
import explanationImage3 from './images/explanation3.jpg';

import desktopArrowImage from './images/desktop-arrow.png';
import mobileArrowImage from './images/mobile-arrow.png';

const desktopFooterImages = [ desktopFooterImage1, desktopFooterImage2, desktopFooterImage3 ];
const mobileFooterImages = [ mobileFooterImage1, mobileFooterImage2, mobileFooterImage3 ];
const explanationImages = [ explanationImage1, explanationImage2, explanationImage3 ];

const SectionDecideApply = props => {
  const [ explanationBlockIndex, setExplanationBlockIndex ] = useState( 1 );

  const { rootClassName, className, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  useEffect(() => {
    const timer = setInterval(() => handleExplanationSwitch(), 3000);

    return () => clearInterval(timer);
  }, [ explanationBlockIndex ]);

  const handleExplanationSwitch = () => {
    if( explanationBlockIndex < 3 )
      setExplanationBlockIndex( explanationBlockIndex + 1 );
    else
      setExplanationBlockIndex( 1 );
  };

  const decideAndApplyTitles = [(
      <div className={css.decideAndApplyTitle}>
        <FormattedMessage
          id="SectionDecideApply.decideAndApplyTitle1"
          values={{
            apply: (
              <span className={css.applySubtitle}>
                <FormattedMessage id="SectionDecideApply.applySubtitle1" />
              </span>
            )
          }}
        />
        <img
          src={ isMobileLayout ? mobileArrowImage : desktopArrowImage }
          className={css.arrowImage}
        />
      </div>
    ), (
      <>
        <div className={css.decideAndApplyTitle}>
          <FormattedMessage id="SectionDecideApply.decideAndApplyTitle2" />
          <img
            src={ isMobileLayout ? mobileArrowImage : desktopArrowImage }
            className={css.arrowImage}
          />
        </div>
        <div className={css.decideAndApplyTitle2}>
          <FormattedMessage
            id="SectionDecideApply.decideAndApplyTitle22"
            values={{
              hardWork: (
                <span className={css.applySubtitle}>
                  <FormattedMessage id="SectionDecideApply.applySubtitle22" />
                </span>
              )
            }}
          />
        </div>
      </>
    ), (
      <>
        <div className={css.decideAndApplyTitle}>
          <FormattedMessage id="SectionDecideApply.decideAndApplyTitle3" />
          <img
            src={ isMobileLayout ? mobileArrowImage : desktopArrowImage }
            className={css.arrowImage}
          />
        </div>
        <div className={css.decideAndApplyTitle2}>
          <FormattedMessage
            id="SectionDecideApply.decideAndApplyTitle32"
            values={{
              itMate: (
                <span className={css.applySubtitle}>
                  <FormattedMessage id="SectionDecideApply.applySubtitle32" />
                </span>
              )
            }}
          />
        </div>
      </>
  )];

  return (
    <>
      <div className={classes}>
        <div className={css.title}>
          <FormattedMessage id="SectionDecideApply.title" />
        </div>
      </div>
      <div className={css.mainBlock}>
        <div className={css.contentBlock}>
          <div className={css.contentText}>
            {decideAndApplyTitles[ explanationBlockIndex - 1 ]}
            <div className={css.description}>
              <FormattedMessage id={`SectionDecideApply.description${explanationBlockIndex}`} />
            </div>
            { explanationBlockIndex === 1 ? (
              <NamedLink name="SearchPage" className={css.actionButton1}>
                <FormattedMessage id="SectionDecideApply.actionButton" />
              </NamedLink>
            ) : null }
          </div>
          <div className={css.contentPicture}>
            <img
              src={explanationImages[ explanationBlockIndex - 1 ]}
              className={css.explanationImage}
            />
          </div>
        </div>
        <div className={css.footerBlock}>
          <div className={css.footerImageWrapper}>
            <img
              src={
                isMobileLayout ?
                  mobileFooterImages[ explanationBlockIndex - 1 ] :
                  desktopFooterImages[ explanationBlockIndex - 1 ]
              }
            />
            { explanationBlockIndex !== 1 ? (
              <div
                className={css.footerImageSection1}
                onClick={() => setExplanationBlockIndex( 1 )}
              >
                &nbsp;
              </div>
            ) : null }
            { explanationBlockIndex === 1 ? (
              <div
                className={css.footerImageSection22}
                onClick={() => setExplanationBlockIndex( 2 )}
              >
                &nbsp;
              </div>
            ) : explanationBlockIndex === 3 ? (
              <div
                className={css.footerImageSection21}
                onClick={() => setExplanationBlockIndex( 2 )}
              >
                &nbsp;
              </div>
            ) : null }
            { explanationBlockIndex !== 3 ? (
              <div
                className={css.footerImageSection3}
                onClick={() => setExplanationBlockIndex( 3 )}
              >
                &nbsp;
              </div>
            ) : null }
          </div>
        </div>
      </div>
      <div className={css.actionFooterLine}>
        <NamedLink name="SearchPage" className={css.actionButton2}>
          <FormattedMessage id="SectionDecideApply.actionButton" />
        </NamedLink>
      </div>
    </>
  );
};

SectionDecideApply.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionDecideApply.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionDecideApply;
