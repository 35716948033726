import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReferralProgram.css';
import { NamedLink } from '../../components';

const ReferralProgram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <h2>For Mentee</h2>
      <p>
        Referral program for Mentee description goes here.
      </p>

      <h2>For Mentor</h2>
      <p>
        Referral program for Mentor description goes here.
      </p>
    </div>
  );
};
ReferralProgram.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReferralProgram.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReferralProgram;
