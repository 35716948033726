import { storableError } from '../../util/errors';
import { sendEnquiry } from '../../util/api';

// ================ Action types ================ //

export const SEND_ENQUIRY_REQUEST = 'app/ContactPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ContactPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ContactPage/SEND_ENQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendEnquiryInProgress: false,
  enquiryIsSent: false,
  sendEnquiryError: null,
};

const aboutPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_ENQUIRY_REQUEST:
      return {
        ...state,
        sendEnquiryInProgress: true,
        enquiryIsSent: true,
        sendEnquiryError: null
      };
    case SEND_ENQUIRY_SUCCESS:
      return {
        ...state,
        sendEnquiryInProgress: false,
        enquiryIsSent: true
      };
    case SEND_ENQUIRY_ERROR:
      return {
        ...state,
        sendEnquiryInProgress: false,
        enquiryIsSent: false,
        sendEnquiryError: payload
      };

    default:
      return state;
  }
};

export default aboutPageReducer;

// ================ Action creators ================ //

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const sendEnquiryToAdmin = ( fullName, email, message ) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());

  return sendEnquiry({ fullName, email, message })
    .then(response => {
        return dispatch(sendEnquirySuccess());
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
    });
};
