import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionAboutTrustedNetwork.module.css';

import explanationImage from './images/explanation.jpg';

const SectionDecideApply = props => {
  const { rootClassName, className, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  const decideAndApplyTitle = (
    <div className={css.decideAndApplyTitle}>
      <FormattedMessage
        id="SectionAboutTrustedNetwork.networkTitle"
        values={{
          mentorsNcoaches: (
            <span className={css.applySubtitle}>
              <FormattedMessage id="SectionAboutTrustedNetwork.networkSubtitle" />
            </span>
          )
        }}
      />
    </div>
  );

  return (
    <>
      <div className={css.mainBlock}>
        <div className={css.contentBlock}>
          <div className={css.contentText}>
            {decideAndApplyTitle}
            <div className={css.description}>
              <FormattedMessage id={"SectionAboutTrustedNetwork.networkDescription1"} />
            </div>
            <div className={css.description}>
              <FormattedMessage id={"SectionAboutTrustedNetwork.networkDescription2"} />
            </div>
          </div>
          <div className={css.contentPicture}>
            <img
              src={explanationImage}
              className={css.explanationImage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

SectionDecideApply.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionDecideApply.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionDecideApply;
